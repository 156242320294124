@import '../../styles/core';

.btn-sm {
  @include font-en-condensed;
  text-transform: uppercase;
  text-indent: $letter-spacing-en;
}

button,
a {
  &.btn-secondary {
    // Bootstrap mixin
    @include button-variant(
      $secondary,
      $secondary,
      $light-purple,
      $light-purple,
      $x-light-purple,
      $x-light-purple
    );
  }
}
