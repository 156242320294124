@import '../../../styles/core';

.root {
  padding: rem(32);
  border: 1px solid $purple;
  border-radius: rem(4);
  text-align: center;

  .imageContainer {
    @include aspect-ratio-img(106, 80, contain);
  }

  span {
    font-size: rem(12);
    font-family: $font-family-base;
    font-weight: bold;
  }
}
