@import '../../styles/core';

.root {
  @include aspect-ratio-img();
  width: rem(36);
  height: rem(36);
  border-radius: 50%;
  overflow: hidden;

  svg {
    @include position(absolute, 50%, 50%);
    color: $light-silver;
    font-size: rem(36);
    transform: translate(-50%, -50%);
  }
}
