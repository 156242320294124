@import '../../../styles/core';

.root {
  @include responsive(padding, $section-padding-spacers);
}

.header {
  position: relative;
  margin-bottom: rem(32);
  text-align: center;

  @include media-breakpoint-up($home-information-breakpoint) {
    margin-bottom: rem(48);
  }
}

.title {
  margin-bottom: rem(16);
  @extend %title;
  @include font-en();

  @include media-breakpoint-up($home-information-breakpoint) {
    margin-bottom: 0;
  }
}

.link {
  @include media-breakpoint-up($home-information-breakpoint) {
    @include position(absolute, $top: 50%, $right: 0);
    transform: translateY(-50%);
  }
}

.content {
  display: grid;
  grid-template-columns: repeat(3, minmax(rem(240), 1fr));
  column-gap: rem(16);
  @include position(relative, $left: 50%);
  width: 100vw;
  margin-left: -50vw;
  overflow-x: scroll;

  @include media-breakpoint-up($home-information-breakpoint) {
    grid-template-columns: repeat(3, 1fr);
    left: auto;
    width: auto;
    margin-left: 0;
  }
}
