@import '../../styles/core';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: rem(24);

  .titleContainer {
    display: flex;
    align-items: center;

    h3 {
      margin-bottom: 0;
      margin-right: rem(16);
    }
  }

  button {
    margin-left: rem(16);
  }
}
