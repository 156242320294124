@import '../../styles/core';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .top {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: rem(20) rem(16);
    margin: rem(48) 0;

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .tag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: rem(66);
  }
}
