@import '../../../styles/core';

.root {
  @include responsive(padding, $section-padding-spacers);
  background-color: $whitesmoke;
  text-align: center;
}

.title {
  margin-bottom: rem(32);
  @include font-en();
  @extend %title;

  @include media-breakpoint-up($home-category-breakpoint) {
    margin-bottom: rem(48);
  }
}

.content {
  display: grid;
  gap: rem(16);
  @include list-unstyled(); // Bootstrap mixin

  @include media-breakpoint-up($home-category-breakpoint) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.item {
  a {
    display: block;
    padding: rem(40) rem(12);
    border-radius: $border-radius-base;
    background-color: $white;

    @include media-breakpoint-up($home-category-breakpoint) {
      padding: rem(40);
    }

    @include media-breakpoint-up(lg) {
      padding: rem(40) rem(72);
    }
  }
}

.itemCaption {
  display: block;
  margin-bottom: rem(32);
  @include font-en-condensed();
  font-size: $font-size-sm;
  text-transform: uppercase;
}

.itemTitle {
  margin-bottom: rem(32);
  font-size: $font-size-lg;
  letter-spacing: 0.2em;
}

.itemImg {
  @include aspect-ratio-img(264, 120, contain);
}
