@import '../../../../styles/core';

.tableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  padding: rem(16);
  border-radius: rem(8);
}

.table {
  table-layout: fixed;
  width: 100%;

  th {
    font-weight: $font-weight-bold;
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid $light-silver;
  }
}

.user {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  column-gap: rem(16);
}

.btns {
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  column-gap: rem(24);
}

.btnEdit {
  display: block;
  margin-left: auto;
  padding: rem(8);
  @include links($link-color, $link-hover-color);
}

.btnDelete {
  @include links($link-color, $link-hover-color);
}
