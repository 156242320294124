@import '../../styles/core';

.root {
  flex-shrink: 0;
  @include position(sticky, 0);
  z-index: 99;
}

.notification {
  padding: rem(8) $header-padding-x-sm;
  background-color: $secondary;
  color: $white;
  font-size: $font-size-xs;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding: rem(12) $header-padding-x-lg;
    font-size: $font-size-sm;
  }

  a {
    &:hover {
      color: inherit;
      opacity: $header-hover-opacity;
    }
  }
}

.navbar {
  // display: flex;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: $header-padding-y-sm $header-padding-x-sm;
  background-color: $white;

  @include media-breakpoint-up(md) {
    padding: $header-padding-y-lg $header-padding-x-lg;
  }

  @include media-breakpoint-up($header-breakpoint) {
    grid-template-columns: auto 1fr auto;
    justify-items: start;
    column-gap: rem(32);
  }
}

// Logo
.logo {
  display: block;
  width: rem(104);

  @include media-breakpoint-up(md) {
    width: rem(116);
  }

  svg {
    float: left;
    width: 100%;
    height: auto;
  }
}

.navItems {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: rem(32);

  button {
    transition: opacity $transition-duration;

    &:hover {
      opacity: $header-hover-opacity;
    }
  }
}

// Search form
.searchForm {
  display: none;

  @include media-breakpoint-up($header-search-form-breakpoint) {
    display: block;
  }
}

// User / Bag buttons
.btnAction {
  svg {
    float: left;
    width: rem(24);
    height: rem(26);
  }
}

// Toggle button
.btnToggle {
  display: grid;
  row-gap: $header-btn-toggle-row-gap;
  padding: rem(4) 0;

  @include media-breakpoint-up($header-breakpoint) {
    display: none;
  }

  &::before,
  &::after {
    display: block;
    width: 2rem;
    border-bottom: 2px solid $border-color-base;
    transition: transform 0.4s;
    content: '';
  }

  &.active {
    &::before {
      transform: translateY(calc(#{$header-btn-toggle-row-gap} / 2 + 50%))
        rotate(45deg);
    }

    &::after {
      transform: translateY(calc(#{$header-btn-toggle-row-gap * -1} / 2 - 50%))
        rotate(-45deg);
    }
  }
}
