@import '../../styles/core';

.root {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(40);
  width: 100%;
  text-align: center;
}

.container {
  display: 'flex';
  flex-direction: column;
  max-width: rem(500);
}

.icon {
  color: $purple;
  font-size: rem(48);
}

.title {
  font-size: rem(20);
  margin-top: rem(24);
  text-align: center;
  color: $purple;
  text-align: 'center';
}

.desc {
  white-space: pre-line;
  font-size: rem(14);
  margin-top: rem(8);
  text-align: center;
}

.backButton {
  width: rem(240);
  height: rem(48);
  margin-top: rem(24);
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  color: $purple;
}
