@import '../../../../styles/core';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;

  .tableContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    padding: rem(16);
    border-radius: rem(8);
  }
}

.rowId {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  span {
    margin-right: rem(4);
  }
}

.rowTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-family: $font-family-en-condensed;
    font-weight: 700;
    letter-spacing: 0.25em;
    font-size: 0.875rem;
    margin-bottom: rem(4);

    button {
      margin-right: rem(8);
    }
  }

  span {
    margin-bottom: rem(4);
  }
}

.rowStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: $font-family-en-condensed;
  font-weight: 700;
  letter-spacing: 0.25em;
  font-size: 0.875rem;
}

.arrowIcon {
  font-size: rem(24);
  color: $gray;
}
