@import '../../../styles/core';

.table {
  width: 100%;
  font-size: $font-size-sm;
  line-height: 1.71;

  span {
    white-space: pre-line;
    margin-bottom: rem(24);
  }

  tbody {
    display: grid;
    row-gap: rem(16);

    @include media-breakpoint-up(md) {
      row-gap: rem(8);
    }
  }

  tr {
    display: grid;
    gap: rem(8) rem(16);

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 4.125fr;
    }
  }

  td {
    white-space: pre-line;
  }
}
