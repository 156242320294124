@import '../../styles/core';

.price {
  @include media-breakpoint-up($product-hero-breakpoint) {
    width: 100%;
  }
}

.priceMain {
  @include font-en(0.083em);
  font-size: rem(24);
  line-height: $line-height-sm;
}

.priceSub {
  font-size: $font-size-sm;
}

.inputWrap {
  display: flex;
  align-items: center;

  :global(.input-label) {
    margin-right: rem(8);
    margin-bottom: 0;
    font-weight: $font-weight-normal;
  }

  input {
    width: rem(80);
    border-color: $border-color-base;
    background-color: $white;
  }
}

/* ------------ Hero ------------ */
.hero {
  display: grid;
  min-height: calc(100vh - #{$header-fallback-height});

  @include media-breakpoint-up($product-hero-breakpoint) {
    grid-template-columns: 2.2fr 1fr;
  }
}

:global(.container) {
  &.heroContainer {
    padding-top: rem(40);
    padding-bottom: rem(40);
    overflow: hidden;

    @include media-breakpoint-up($product-hero-breakpoint) {
      display: grid;
      align-content: center;
      max-width: none;
      padding-left: rem(32);
      padding-right: rem(32);
    }
  }
}

.heroTitle {
  font-size: $font-size-lg;
  @extend %mb-md;

  @include media-breakpoint-up(md) {
    font-size: rem(36);
  }
}

.heroContent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: $product-hero-content-column-gap * -1;
  margin-bottom: rem(24) + $product-hero-content-row-gap * -1;

  @include media-breakpoint-up($product-hero-breakpoint) {
    margin-right: $product-hero-content-column-gap-lg * -1;
  }

  > * {
    margin-right: $product-hero-content-column-gap;
    margin-bottom: $product-hero-content-row-gap;

    @include media-breakpoint-up($product-hero-breakpoint) {
      margin-right: $product-hero-content-column-gap-lg;
    }
  }
}

.heroForm {
  margin-right: rem(24);

  @include media-breakpoint-up($product-hero-breakpoint) {
    margin-right: $product-hero-content-column-gap-lg;
  }
}

.btnSubmit {
  grid-column: 1 / -1;
}

.heroNotes {
  color: $gray;
  font-size: $font-size-xs;
  line-height: 2;
  white-space: pre-line;
}

/* ------------ Features ------------ */
.features {
  display: flex;
  align-items: center;
  padding: rem(40) 0 rem(24);
  background-color: $whitesmoke;

  @include media-breakpoint-up($product-features-breakpoint) {
    padding-bottom: rem(40);
  }
}

.featuresContent {
  width: 100%;

  @include media-breakpoint-up($product-features-breakpoint) {
    overflow-x: auto;
  }
}

.featuresContentInner {
  display: grid;
  gap: rem(24) $product-features-content-column-gap;
  max-width: rem(400);
  margin: 0 auto;
  padding: 0 rem(32);

  @include media-breakpoint-up(md) {
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
    max-width: map-get($max-widths, md);
    padding: rem(40) 0;
  }

  @include media-breakpoint-up($product-features-breakpoint) {
    grid-template-columns: none;
    grid-auto-columns: $product-features-item-size;

    grid-auto-flow: column;
    justify-content: start;
    min-width: min-content;
    max-width: none;
    padding: rem(24) rem(48);
  }
}

/* ------------ Details ------------ */
.details {
  @include responsive(padding, $section-padding-spacers);
}

.detailsContainerInner {
  max-width: 672px;
  margin: 0 auto;
  padding: 0 rem(24);

  @include media-breakpoint-up(md) {
    padding: 0;
  }
}

.btnToggle {
  display: flex;
  align-items: center;
  margin: 0 auto;
  @include links($secondary, $light-purple);
  transition: color $transition-duration;

  &::after {
    display: block;
    width: rem(10);
    height: rem(10);
    margin-left: rem(12);
    border: solid $secondary;
    border-width: 0 $border-width-base $border-width-base 0;
    transform: translateY(rem(2) * -1) rotate(45deg);
    transition: transform $transition-duration,
      border-color $transition-duration;
    content: '';
  }

  &:hover {
    &::after {
      border-color: $light-purple;
    }
  }

  &.active {
    &::after {
      transform: translateY(rem(3)) rotate(-135deg);
    }
  }
}

/* ------------ Reviews ------------ */
.reviews {
  @include responsive(padding, $section-padding-lg-spacers);
  background-color: $whitesmoke;
}

.reviewsTitle {
  @extend %mb-lg;
  text-align: center;
}

.reviewsList {
  @include list-unstyled(); // Bootstrap mixin
}

.reviewsItem {
  display: grid;
  grid-template-columns: repeat(2, auto) 1fr;
  align-items: center;
  justify-content: start;
  gap: rem(16) rem(24);
  border-bottom: $border-width-base solid $border-color-base;
  padding: rem(16) rem(8);

  &:first-child {
    padding-top: 0;
  }

  span {
    &:not(.reviewsItemContent) {
      color: $gray;
      font-size: $font-size-xs;
    }
  }
}

.reviewsItemRating {
  display: flex;

  svg {
    color: $white;

    &.filled {
      color: $pink;
    }
  }
}

.reviewsItemContent {
  grid-column: 1 / -1;
}

/* ------------ Call to action ------------ */
.cta {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
    @include position(sticky, $bottom: 0);
    padding: rem(16) 0;
    background-color: $white;
  }
}

.ctaContent {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  column-gap: rem(12);
}

.ctaTitle {
  font-size: rem(18);
  font-weight: $font-weight-bold;
}

.formAddToCart {
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: rem(92);
  }
}
