@import '../../styles/core';

.root {
  @include responsive(padding, $section-padding-lg-spacers);
}

.entryTitle {
  margin-bottom: rem(16);
  @extend %title;

  @include media-breakpoint-up(md) {
    margin-bottom: rem(48);
  }
}

.date {
  display: block;
  @extend %mb-xl;
  @include font-en-condensed();
  font-size: $font-size-xs;

  @include media-breakpoint-up(md) {
    font-size: rem(24);
  }
}

.entryThumbnail {
  @include aspect-ratio-img(288, 240, contain);
  @extend %mb-lg;

  @include media-breakpoint-up(md) {
    @include aspect-ratio-img(784, 480, null);
  }
}

.entryContent {
  font-size: $font-size-sm;
  text-align: left;
  white-space: pre-line;

  @include media-breakpoint-up(md) {
    font-size: $font-size-base;
  }

  /* prettier-ignore */
  h2, h3, h4, h5, h6 {
    @extend %mb-lg;
  }

  /* prettier-ignore */
  h2 {
    @include responsive(font-size, (xs: rem(18), md: rem(20)));
  }

  /* prettier-ignore */
  h3 {
    @include responsive(font-size, (xs: rem(16), md: rem(18)));
  }

  /* prettier-ignore */
  h4 {
    @include responsive(font-size, (xs: rem(14), md: rem(16)));
  }

  /* prettier-ignore */
  h5 {
    @include responsive(font-size, (xs: rem(13), md: rem(15)));
  }

  /* prettier-ignore */
  h6 {
    @include responsive(font-size, (xs: rem(12), md: rem(14)));
  }

  p {
    margin-bottom: 1em;
  }

  a {
    @include links($secondary);
    text-decoration: underline;
    word-break: break-all;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}
