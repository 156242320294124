@import './styles/core';
@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@700&family=Montserrat:wght@400;700&family=Mulish:wght@800&family=Noto+Sans+JP:wght@400;700&family=Noto+Serif+JP:wght@900&family=Roboto+Condensed:wght@700&display=swap'); // Google fonts
@import '~bootstrap/scss/bootstrap'; // Bootstrap

/**
 * ----------------------------------------
 * Base Styles
 * ----------------------------------------
 */

html {
  font-size: 16px; /* base font size */
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  transition: $transition-base;
}

button {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  font: inherit;
  appearance: none;
}

ul,
ol {
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

/* Typography
-------------------- */
/* prettier-ignore */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  @include responsive(margin-bottom, $margin-bottom-sm-spacers);
}

h1,
.h1 {
  @include responsive(font-size, $h1-font-sizes);
}

h2,
.h2 {
  @include responsive(font-size, $h2-font-sizes);
}

.title {
  @extend %title;
}

.title-lg {
  @extend %title-lg;
}

/* Container
-------------------- */
.container-inner {
  max-width: $inner-max-width;
  margin: 0 auto;
}

/* App
-------------------- */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
}

.app-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $dark-gray;
}

// textarea:focus,
// input[type='text']:focus,
// input[type='password']:focus,
// input[type='datetime']:focus,
// input[type='datetime-local']:focus,
// input[type='date']:focus,
// input[type='month']:focus,
// input[type='time']:focus,
// input[type='week']:focus,
// input[type='number']:focus,
// input[type='email']:focus,
// input[type='url']:focus,
// input[type='search']:focus,
// input[type='tel']:focus,
// input[type='color']:focus,
// .uneditable-input:focus {
//   border-color: $light-purple;
//   box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px $light-purple;
//   outline: 0 none;
// }

// input::-webkit-input-placeholder {
//   text-overflow: 'ellipsis !important';
//   color: $gray;
//   font-size: 14px;
// }

// input:-webkit-autofill {
//   box-shadow: 0 0 0px 1000px $light-blue inset;
//   transition: background-color 5000s ease-in-out 0s;
// }
