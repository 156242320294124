@import '../../../styles/core';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: $primary;
  min-height: 100vh;
}

.root:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  border-left: 60vw solid transparent;
  border-bottom: 70vh solid white;
  width: 0;
}

.container {
  position: relative;
  width: 100%;
  padding: 1.5rem;
}

.logo {
  display: block;
  width: rem(160);
  margin-bottom: rem(16);
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-down(md) {
    width: rem(120);
  }
}

.header {
  font-size: rem(40);
  color: white !important;
  text-align: center;
  margin-bottom: rem(60);
  @include media-breakpoint-down(md) {
    font-size: rem(32);
    margin-bottom: rem(16);
  }
}

.title {
  font-weight: bold;
  font-style: normal;
  font-size: rem(24);
  color: white;
  text-align: center;
  margin-bottom: rem(16);
}

.description {
  font-size: rem(16);
  line-height: rem(19);
  text-align: center;
  color: white;
  margin-bottom: rem(48);
}

.prompt {
  font-weight: bold;
  font-size: rem(16);
  color: white;
  text-decoration: underline;
  text-align: center;
}
