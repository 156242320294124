@import '../../styles/core';

.root {
  flex-shrink: 0;
}

.campaign {
  color: $white;
  background-image: url('../../assets/images/footer-giving-vaccine.jpg');
  background-size: cover;
  background-position: 52%;
  font-size: rem(10);

  @include media-breakpoint-up($footer-breakpoint) {
    background-position: center;
  }

  span {
    display: block;
  }
}

.campaignContainer {
  display: grid;
  row-gap: rem(32);
  padding: rem(16);

  @include media-breakpoint-up($footer-breakpoint) {
    grid-template-columns: repeat(2, auto);
    align-items: center;
    justify-content: space-between;
    padding: rem(24) rem(48);
  }

  @include media-breakpoint-up(xl) {
    padding-top: rem(40);
    padding-bottom: rem(40);
  }
}

.campaignTitleWrap {
  font-family: $font-family-en-special;
  line-height: normal;
}

.campaignSubtitle {
  @include media-breakpoint-up(md) {
    font-size: $font-size-base;
  }
}

.campaignTitle {
  font-size: rem(16);
  font-weight: $font-weight-bold;

  @include media-breakpoint-up(md) {
    font-size: rem(36);
  }
}

.logoVaccine {
  width: rem(127);
  height: rem(40);
  margin-bottom: rem(8);

  @include media-breakpoint-up($footer-breakpoint) {
    width: auto;
    height: auto;
  }
}

.campaignDescription {
  @include media-breakpoint-up(md) {
    font-size: $font-size-sm;
  }
}

.container {
  position: relative;
  padding: rem(48) rem(16) rem(24);

  @include media-breakpoint-up($footer-breakpoint) {
    padding: rem(24) rem(48);
  }
}

.navLinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include list-unstyled(); // Bootstrap mixin
  margin-right: rem(16) * -1; // Same as li negative margin-right
  margin-bottom: rem(32);
  font-size: $font-size-xs;

  @include media-breakpoint-up($footer-breakpoint) {
    justify-content: flex-end;
    margin-right: rem(20) * -1; // Same as li negative margin-right
    margin-bottom: 0;
    font-size: $font-size-sm;
  }

  li {
    margin-right: rem(16); // Same as parent positive margin-right
    margin-bottom: rem(32);

    @include media-breakpoint-up($footer-breakpoint) {
      margin-right: rem(20); // Same as parent positive margin-right
    }
  }
}

.block {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: rem(32) rem(44);
  margin-bottom: rem(64);

  @include media-breakpoint-up($footer-breakpoint) {
    grid-auto-flow: column;
    justify-content: start;
    margin-bottom: rem(44);
  }
}

.logo {
  display: block;
  width: rem(168);

  img {
    width: 100%;
  }
}

.socialLinks {
  @include list-unstyled(); // Bootstrap mixin
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  column-gap: rem(20);

  svg {
    width: rem(24);
    height: rem(24);

    defs {
      path {
        fill: inherit;
      }
    }
  }
}

.copyright {
  display: block;
  color: $gray;
  font-size: rem(10);
}

.btnToTop {
  @include position(absolute, $right: rem(20), $bottom: rem(20));

  @include media-breakpoint-up($footer-breakpoint) {
    @include position(null, $right: rem(68), $bottom: rem(48));
  }
}
