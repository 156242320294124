@import '../../styles/core';

.hero {
  display: flex;
  z-index: 9;
  min-height: calc(100vh - #{$header-fallback-height});
  background-image: url('../../assets/images/top01.jpg');
  background-size: cover;
  background-position: right 29% center;
  overflow: hidden;

  @include media-breakpoint-up($bihada-factory-hero-breakpoint) {
    background-position: right 47% center;
  }
}

.heroContainer {
  display: grid;
  align-items: end;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 $gutter-width;

  @include media-breakpoint-up(sm) {
    max-width: map-get($max-widths, sm);
  }

  @include media-breakpoint-up($bihada-factory-hero-breakpoint) {
    max-width: 1208px;
    align-items: center;
  }
}

.heroText {
  position: relative;
  z-index: 9;
  width: 98%;
  max-width: 25rem;
  height: auto;
  margin: 0 auto rem(24);

  @include media-breakpoint-up($bihada-factory-hero-breakpoint) {
    width: 58%;
    max-width: none;
    margin: 0;
  }

  @include media-breakpoint-up(lg) {
    width: 52%;
  }
}

// Mobile
.heroCharacter {
  position: absolute;

  @include media-breakpoint-up($bihada-factory-hero-breakpoint) {
    display: none;
  }

  &.heroHearu {
    @include position(null, $right: -0.5rem, $bottom: 27%);
    width: 27.5%;

    @include media-breakpoint-up(sm) {
      right: -3rem;
    }
  }

  &.heroNidoru {
    @include position(null, $top: -0.5rem, $left: 0);
    width: 15%;

    @include media-breakpoint-up(sm) {
      left: 50%;
      margin-left: -50vw;
    }
  }

  &.heroMamoru {
    @include position(null, $left: 0, $bottom: 0);
    width: 17%;
  }
}

// Desktop
.heroCharacterPc {
  position: absolute;
  display: none;

  @include media-breakpoint-up($bihada-factory-hero-breakpoint) {
    display: inline;
  }

  &.heroHearu {
    @include position(null, $right: -4rem, $bottom: 24%);
    width: rem(142);

    @include media-breakpoint-up(lg) {
      right: 3.25rem;
    }
  }

  &.heroNidoru {
    @include position(null, $top: -1.25rem, $left: 1rem);
    width: rem(256);
  }

  &.heroIkumi {
    @include position(null, $left: 0, $bottom: -1.75rem);
    width: rem(212);
  }

  &.heroMamoru {
    @include position(null, $left: 44%, $bottom: 2rem);
    width: rem(184);
  }
}

.heroImg {
  display: none;

  @include media-breakpoint-up($bihada-factory-hero-breakpoint) {
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: left;
  }
}
