@import '../../styles/core';

%acropassTitle {
  font-size: rem(20);

  @include media-breakpoint-up(md) {
    font-size: rem(36);
  }
}

.textColored {
  color: $yellow;
}

.overlay {
  @include overlay($black, 0.8);
}

/* ----- Main content ----- */
.section {
  @include responsive(padding, $section-padding-spacers);
  background-color: $black;
  color: $white;
  text-align: center;
}

.sectionTitle {
  @extend %mb-xl;
  @extend %acropassTitle;
}

.products {
  display: grid;
  grid-template-columns: minmax(0, rem(224));
  justify-content: center;
  gap: rem(20) rem(16);
  @include list-unstyled(); // Bootstrap mixin

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, 1fr);
    gap: rem(40) rem(16);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(auto-fit, minmax(0, rem(224)));
  }
}

.productContent {
  display: block;
  margin-bottom: rem(16);
  padding: rem(24) rem(32) rem(32);
  border-radius: $border-radius-lg;
  background-color: $white;
  color: $text-primary;
}

.productCategory {
  display: block;
  margin-bottom: rem(32);
  @include font-en-condensed(0.33em);
  font-size: rem(12);
  text-transform: uppercase;
}

.productImg {
  @include aspect-ratio-img(160, 120, contain);
}

.productTitle {
  margin-bottom: 0;
  font-size: $font-size-sm;
  white-space: pre-line;

  a {
    &:hover {
      color: rgba($white, $link-hover-opacity);
    }
  }
}

/* ----- Animation slides ----- */
.slideWrapper {
  @include position(fixed, 0, 0, 0, 0);
  z-index: 9;
  background-color: $black;
  color: $white;
  animation: fadeout 1.2s
    (
      $acropass-slide-initial-animation-delay +
        $acropass-slide-animation-duration *
        (2 / 3 * ($acropass-num-slides - 1) + 1)
    )
    forwards;
}

@keyframes fadeout {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.slide {
  @include position(absolute, 0, 0, 0, 0);
  display: flex;
  align-items: center;
  @include responsive(padding, $section-padding-spacers);
  background-color: $black;
  opacity: 0;
  visibility: hidden;
  animation: fadein $acropass-slide-animation-duration forwards;

  // opacity 0 (fadein start) : opacity 1 : opacity 0 (fadeout & next slide animation start)
  // = duration 1 : 1 : 1
  @for $i from 1 through $acropass-num-slides {
    &:nth-child(#{$i}) {
      animation-delay: $acropass-slide-initial-animation-delay +
        ($acropass-slide-animation-duration * 2 / 3 * ($i - 1));
    }
  }

  &.slideBg01 {
    background-image: url('../../assets/images/about01.jpg');
    background-size: cover;
    background-position: 20%;

    @include media-breakpoint-up(md) {
      background-position: 30% 40%;
    }

    @include media-breakpoint-up(lg) {
      background-position-x: 40%;
    }
  }

  &.slideBg02 {
    background-image: url('../../assets/images/about02.jpg');
    background-size: cover;
    background-position: 65% 90%;
  }
}

// opacity 0 (fadein start) : opacity 1 : opacity 0 (fadeout & next slide animation start)
// = duration 1 : 1 : 1
@keyframes fadein {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  #{1 / 3 * 100%},
  #{2 / 3 * 100%} {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

:global(.container) {
  &.slideContainer {
    padding: 0 rem(24);

    @include media-breakpoint-up(sm) {
      padding: 0 $gutter-width;
    }
  }
}

.slideContentHalf {
  @include media-breakpoint-up(sm) {
    max-width: rem(380);
    margin-left: auto;
    margin-right: 0;
  }

  @include media-breakpoint-up(md) {
    width: 50%;
    max-width: rem(472);
  }

  // Overlay
  @extend .overlay;
  &::before {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  > * {
    position: relative;
  }
}

.slideContent {
  display: grid;
  gap: rem(32) rem(40);
  position: relative;

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1.7fr;
    align-items: center;
  }

  @include media-breakpoint-up(lg) {
    column-gap: rem(80);
  }
}

.slideImg {
  width: 100%;
  max-width: rem(240);
  margin: 0 auto;
  @include aspect-ratio-img(1, 1);

  @include media-breakpoint-up(md) {
    max-width: none;
  }

  img {
    border-radius: 50%;
  }
}

.slideVideo {
  @include aspect-ratio(1, 1, null);
  width: 100%;
  max-width: rem(240);
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  transform: translateZ(0); // For safari (overflow hidden & border radius)

  @include media-breakpoint-up(md) {
    max-width: none;
  }

  &.border {
    border: 2px solid $secondary;
  }

  iframe {
    @include position(absolute, 50%, 50%);
    width: 200%;
    height: 200%;
    transform: translate(-50%, -50%);
  }
}

.slideCaption {
  display: flex;
  align-items: center;
  margin-bottom: rem(8);
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;

  @include media-breakpoint-up(md) {
    margin-bottom: rem(16);
    font-size: $font-size-base;
  }

  &::before {
    display: block;
    width: 1em;
    height: 1em;
    margin-right: rem(8);
    border-radius: 50%;
    background-color: $yellow;
    content: '';
  }
}

.slideTitleSm {
  @extend %acropassTitle;
  @extend %mb-sm;

  &.mrSpacer {
    margin-right: rem(13);

    @include media-breakpoint-up(sm) {
      margin-right: 0;
    }
  }

  &.widthLg {
    width: calc(100% + #{rem(26)});

    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }
}

.slideTitleMd {
  font-size: rem(32);
  margin-bottom: 0;
  line-height: $line-height-base;

  @include media-breakpoint-up(md) {
    font-size: rem(40);
  }

  @include media-breakpoint-up(lg) {
    font-size: rem(48);
  }
}

.slideTitleLg {
  @extend %title-lg;
  margin-bottom: 0;
  line-height: $line-height-base;
}

.slideDescription {
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;

  @include media-breakpoint-up(md) {
    font-size: $font-size-base;
  }
}

.circle {
  width: rem(80);
  height: rem(80);
  border: 2px solid $secondary;
  border-radius: 50%;

  &.position01 {
    @include position(absolute, $right: 37%, $bottom: 5%);
  }

  &.position02 {
    @include position(absolute, $top: 36%, $left: 18.75%);
  }
}

.showAll {
  opacity: 1 !important;
  position: relative !important;
  visibility: visible !important;
  animation: unset !important;
  min-height: calc(100vh - #{$header-fallback-height});
}
