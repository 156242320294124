@import '../../styles/core';

.title {
  margin-bottom: rem(16);
}

.form {
  display: grid;
  row-gap: rem(24);
}

.note {
  margin-top: rem(8) * -1;
  font-size: $font-size-sm;

  // a {
  //   @include links($text-decoration: underline);
  // }
}

.btnSubmit {
  margin-top: rem(16);
}

.inner {
  display: grid;
  justify-items: center;
  row-gap: rem(24);
  text-align: center;
  padding: rem(60) rem(64);
}

.warning {
  font-size: rem(40);
  color: $primary;
}

.actionBtns {
  justify-self: stretch;
  display: grid;
  grid-auto-flow: column;
  column-gap: rem(20);
  margin-top: rem(20);
}

.deleteButton {
  background-color: $pink !important;
}
