/**
 * ----------------------------------------
 * Colors
 * ----------------------------------------
 */

// Black
$black: #000;
$off-black: #1e1e20;
$off-black-translucent: rgba($off-black, 0.5);
$matte-black: #282828;

// White
$white: #fff;

// Purple
$purple: #574099;
$purple-translucent: rgba($purple, 0.5);
$light-purple: #7455cc;
$x-light-purple: lighten($light-purple, 2.5%);
$royal-purple: #8b5aa0;
$red-purple: #c13780;

// Pink
$pink: #e4006f;
$raspberry: #e83f6d;
$light-raspberry: #e36389;

// Yellow
$yellow: #e3b500;
$yellow-translucent: rgba($yellow, 0.5);
$light-yellow: #feda76;

// Orange
$orange: #f29200;
$light-orange: #e9da96;

// blue
$blue: #607ebe;
$light-blue: #c4d6ef;
$pacific-blue: #0d98c3;
$light-pacific-blue: #0aafba;
$skyblue: #00a0c5;
$light-skyblue: #abd5d3;
$bright-skyblue: #329dae;
$cyan: #70c5c3;
$dark-cyan: #009fc4;

// Green
$blue-green: #649dd0;
$light-green: #acd0c6;

// Gray
$gray: #9d97a3;
$whitesmoke: #f5f5f7;
$light-whitesmoke: #fafafa;
$silver: #bcbcbc;
$dark-silver: darken($silver, 20%);
$light-silver: #c4c4c4;

// Theme colors
$primary: $off-black;
$secondary: $purple;
$text-primary: $matte-black;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  light: $white,
);

$dropzone-colors: (
  dragActive: $purple,
  dragAccept: $purple,
  dragReject: $pink,
);

// Body color
$body-color-base: $text-primary;

/* Old colors
-------------------- */
// gray
$dark-gray: #333a41;
$light-gray: #f7f9fc;

// gradation
$gradient-pink-purple: linear-gradient(125.07deg, $pink 0%, $purple 66.18%);

/**
 * ----------------------------------------
 * Links
 * ----------------------------------------
 */

$link-color-base: currentColor;
$link-hover-color: $secondary;
$link-hover-decoration: none;
$link-hover-opacity: 0.7;

/**
 * ----------------------------------------
 * Typography
 * ----------------------------------------
 */

$font-family-base: 'Noto Sans JP', sans-serif;
$font-family-secondary: 'Noto Serif JP', serif;
$font-family-en: 'Mulish', sans-serif;
$font-family-en-secondary: 'Bodoni Moda', serif;
$font-family-en-condensed: 'Roboto Condensed', sans-serif;
$font-family-en-special: 'Montserrat', sans-serif;

$font-size-base: rem(16);
$font-size-lg: rem(20);
$font-size-sm: rem(14);
$font-size-xs: rem(12);

$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-ex-bold: 800;
$font-weight-black: 900;

$line-height-base: 1.5;
$line-height-sm: 1.33;

$letter-spacing-en: 0.25em;

$headings-margin-bottom: rem(32);
$headings-font-weight: 700;
$headings-line-height: $line-height-sm;

$title-font-sizes: (
  xs: rem(24),
  md: rem(36),
);

$title-lg-font-sizes: (
  xs: rem(36),
  md: rem(48),
);

$h1-font-sizes: $title-font-sizes;
$h2-font-sizes: $title-font-sizes;

$paragraph-margin-bottom: 0;

/**
 * ----------------------------------------
 * Spacing
 * ----------------------------------------
 */

$margin-bottom-sm-spacers: (
  xs: rem(16),
  md: rem(32),
);

$margin-bottom-md-spacers: (
  xs: rem(16),
  md: rem(36),
);

$margin-bottom-lg-spacers: (
  xs: rem(24),
  md: rem(48),
);

$margin-bottom-xl-spacers: (
  xs: rem(40),
  md: rem(80),
);

$section-padding-spacers: (
  xs: rem(40) 0,
  md: rem(80) 0,
);

$section-padding-lg-spacers: (
  xs: rem(56) 0,
  md: rem(112) 0,
);

/**
 * ----------------------------------------
 * Breakpoints
 * ----------------------------------------
 */

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1296px,
);

/**
 * ----------------------------------------
 * Container
 * ----------------------------------------
 */

$max-widths: (
  sm: 540px,
  md: 720px,
  lg: 976px,
  xl: 1140px,
);

$gutter-width: 16px;

$inner-max-width: 784px;

/**
 * ----------------------------------------
 * Border
 * ----------------------------------------
 */

$border-width-base: 1px;
$border-color-base: $primary;

$border-radius-base: rem(4);
$border-radius-lg: rem(16);
$border-radius-sm: rem(2);

/**
 * ----------------------------------------
 * Opacity
 * ----------------------------------------
 */
$hover-opacity: $link-hover-opacity;

/**
 * ----------------------------------------
 * Transition
 * ----------------------------------------
 */

$transition-duration: 0.3s;
$transition-base: $transition-duration;

/**
 * ----------------------------------------
 * Buttons
 * ----------------------------------------
 */

$btn-padding-y: rem(8);
$btn-padding-x: rem(24);
$btn-border-radius: 50rem;
$btn-font-size: $font-size-xs;
$btn-line-height: $line-height-sm;

$btn-padding-y-lg: rem(12);
$btn-padding-x-lg: rem(32);
$btn-border-radius-lg: 50rem;
$btn-font-size-lg: $font-size-base;
$btn-line-height-lg: $line-height-base;

$btn-padding-y-sm: rem(3);
$btn-padding-x-sm: rem(6);
$btn-border-radius-sm: 0;
$btn-font-size-sm: rem(10);
$btn-line-height-sm: 1;

$btn-border-width: $border-width-base;

$btn-font-weight: $font-weight-bold;

$btn-transition: color $transition-duration,
  background-color $transition-duration, border-color $transition-duration,
  box-shadow $transition-duration;

/**
 * ----------------------------------------
 * Forms
 * ----------------------------------------
 */

$label-margin-bottom: 0;

$input-padding-y: rem(11);
$input-padding-x: rem(12);

$input-padding-y-sm: rem(6);
$input-padding-x-sm: rem(8);
$input-focus-color-opacity: 0.25;

$input-border-width: $border-width-base;
$input-border-color: $whitesmoke;
$input-bg: $whitesmoke;

$form-feedback-margin-top: rem(8);
$form-feedback-font-size: $font-size-sm;
$form-feedback-valid-color: $input-border-color;
$form-feedback-invalid-color: $pink;

/**
 * ----------------------------------------
 * Header
 * ----------------------------------------
 */

$header-breakpoint: xl;
$header-search-form-breakpoint: md;
$header-hover-opacity: $link-hover-opacity;
$header-btn-toggle-row-gap: rem(12);

$header-padding-y-sm: rem(20);
$header-padding-x-sm: rem(16);

$header-padding-y-lg: rem(24);
$header-padding-x-lg: rem(48);

$header-fallback-height: 66px;

/**
 * ----------------------------------------
 * Footer
 * ----------------------------------------
 */

$footer-breakpoint: md;

/**
 * ----------------------------------------
 * Home
 * ----------------------------------------
 */

$home-hero-breakpoint: sm;
$home-section-breakpoint: md;
$home-information-breakpoint: md;
$home-category-breakpoint: md;

/**
 * ----------------------------------------
 * Pages
 * ----------------------------------------
 */

/* History
-------------------- */
$timeline-breakpoint: md;
$timeline-border-width: 2px;
$timeline-border-color: $border-color-base;
$timeline-dot-size: rem(16);
$timeline-dot-translate-x: calc(-50% - (#{$timeline-border-width} / 2));
$timeline-text-font-sizes: (
  xs: $font-size-sm,
  md: $font-size-base,
);
$timeline-year-inner-size: rem(96);

/* Bihada Factory
-------------------- */
$bihada-factory-title-margin-bottom: (
  xs: rem(24),
  md: rem(32),
);

$bihada-factory-hero-breakpoint: md;

$bihada-factory-mission-breakpoint: md;
$bihada-factory-mission-slider-breakpoint: md;
$bihada-factory-mission-card-shadow-y: rem(8);
$bihada-factory-mission-card-shadow-blur: rem(16);
$bihada-factory-mission-slide-shadow-offset: $bihada-factory-mission-card-shadow-y +
  $bihada-factory-mission-card-shadow-blur;

$bihada-factory-profile-breakpoint: md;
$bihada-factory-profile-colors: (
  blue: (
    background-color: $light-blue,
    color: $blue,
  ),
  green: (
    background-color: $light-green,
    color: $blue-green,
  ),
  pink: (
    background-color: $light-raspberry,
    color: $raspberry,
  ),
  yellow: (
    background-color: $light-yellow,
    color: $orange,
  ),
);

/* About
-------------------- */
$about-breakpoint: md;

/* Brand Story
-------------------- */
$brand-story-hero-breakpoint: md;

$brand-story-vision-content-inner-padding-x: 12.5%;
$brand-story-vision-item-offset: rem(16);

$brand-story-identity-breakpoint: md;
$brand-story-identity-item-img-size: rem(160);

$brand-story-policy-breakpoint: md;
$brand-story-essence-breakpoint: md;

/* Acropass
-------------------- */
$acropass-slide-animation-duration: 4s;
$acropass-slide-initial-animation-delay: 2s;
$acropass-num-slides: 8;

/* Karada Arrange
-------------------- */
$karada-arrange-slide-animation-duration: $acropass-slide-animation-duration;
$karada-arrange-slide-initial-animation-delay: $acropass-slide-initial-animation-delay;
$karada-arrange-num-slides: 5;

/* Product
-------------------- */
$product-hero-breakpoint: lg;
$product-hero-content-row-gap: rem(16);
$product-hero-content-column-gap: rem(8);
$product-hero-content-column-gap-lg: rem(44);

$product-features-breakpoint: lg;
$product-features-content-column-gap: rem(16);
$product-features-item-size: rem(384);

$product-modal-breakpoint: md;
$product-modal-hover-transition-duration: 0.5s;

/* Archive
-------------------- */
$archive-breakpoint: md;

/* FAQ
-------------------- */
$faq-breakpoint: md;

/* Contact
-------------------- */
$contact-breakpoint: md;

/**
 * ----------------------------------------
 * Others
 * ----------------------------------------
 */

$page-layout-breakpoint: lg;

$blog-grid-gap-base: rem(16);

$main-gap: rem(32);
$main-gap-sm: rem(24);
$main-padding: $main-gap;

$header-navbar-padding-y: rem(14);
$header-navbar-padding-x: $main-gap;
