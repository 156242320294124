@import '../../styles/core';

.content {
  padding: rem(40) 0;

  @include media-breakpoint-up(md) {
    padding: rem(48) rem(32) rem(80);
  }

  @include media-breakpoint-up(lg) {
    padding: rem(80);
  }
}

.table {
  width: 100%;
  font-size: $font-size-sm;

  @include media-breakpoint-up(md) {
    font-size: $font-size-base;
  }

  tr {
    display: grid;
    padding: rem(16) 0;
    border-bottom: $border-width-base solid $border-color-base;

    @include media-breakpoint-up($about-breakpoint) {
      // grid-template-columns: rem(160) 1fr;
      grid-template-columns: 1fr 3.9fr;
      padding: rem(32) 0;
    }
  }

  th {
    font-weight: $font-weight-bold;
  }

  td {
    white-space: pre-wrap;

    br {
      @include media-breakpoint-up($about-breakpoint) {
        display: none;
      }
    }
  }
}
