@import '../../styles/core';

.section {
  @include responsive(padding, $section-padding-lg-spacers);
}

.title {
  @extend %mb-lg;
  @extend %title;
  text-align: center;
}

.categoriesWrap {
  @include position(relative, $left: 50%);
  width: 100vw;
  margin-left: -50vw;
  @extend %mb-xl;
  overflow-x: scroll;
}

.categories {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: rem(8);
  @include list-unstyled(); // Bootstrap mixin
  width: min-content;
  margin: 0 auto;
  padding: 0 $gutter-width;

  // Container max widths
  @each $breakpoint, $max-width in $max-widths {
    @include media-breakpoint-up($breakpoint) {
      max-width: $max-width;
    }
  }

  @include media-breakpoint-up($archive-breakpoint) {
    justify-content: center;
    gap: rem(16);
  }
}

.btnCategory {
  white-space: nowrap;
}

.content {
  display: grid;
  gap: $blog-grid-gap-base;

  @include media-breakpoint-up($archive-breakpoint) {
    grid-template-columns: repeat(3, 1fr);
    row-gap: rem(48);
  }
}

.pageNavigation {
  padding: rem(32) 0;
  background-color: $secondary;

  @include media-breakpoint-up($archive-breakpoint) {
    padding: rem(48) 0;
  }
}

.pagination {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  column-gap: rem(18);
  @include list-unstyled(); // Bootstrap mixin
  color: $white;
  @include font-en-condensed;
  font-size: rem(24);
  line-height: $line-height-sm;

  @include media-breakpoint-up($archive-breakpoint) {
    column-gap: rem(24);
    font-size: rem(36);
  }

  button {
    transition: opacity $transition-duration;

    &:not(.active) {
      &:hover {
        opacity: $link-hover-opacity;
      }
    }
  }
}
