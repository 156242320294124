@import '../../styles/core';

.root {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'sidebar header'
    'sidebar main';
  @include position(fixed, 0, 0);
  width: 100%;
  height: 100%;
}
