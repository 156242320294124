@import '../../../styles/core';

.root {
  @include position(
    absolute,
    $top: calc(100% + #{$header-navbar-padding-y}),
    $right: 0
  );
  width: max-content;
  max-width: rem(320);
  margin-top: rem(4);
  padding: rem(12) rem(16);
  background-color: $white;
  font-size: $font-size-sm;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: rem(8);
}

.title {
  display: block;
  margin-bottom: rem(8);
}

.readAll {
  text-decoration: underline;
}

.list {
  @include list-unstyled(); // Bootstrap mixin
  margin-bottom: rem(10);
}

.item {
  border-bottom: $border-width solid $silver;

  &:first-child {
    border-top: $border-width solid $silver;
  }
}

.itemInner {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: rem(8);
  padding: rem(8);
  // @include links(inherit, inherit, null, background-color);
  font-size: rem(12);

  &:hover {
    background-color: $purple-translucent;
  }

  &.unread {
    background-color: $off-black-translucent;

    &:hover {
      background-color: $yellow-translucent;
    }
  }
}

.avatar {
  grid-row: 1 / -1;
}

.time {
  color: $dark-silver;
  font-size: rem(12);
}

.link {
  display: block;
  text-align: center;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.videoTitle {
  font-size: rem(12);
  color: black;
  margin-bottom: rem(2);
}

.tag {
  color: white;
  padding: rem(2) rem(4);
  border-radius: rem(2);
  font-size: rem(12);
  margin-bottom: rem(4);
}

.channelContainer {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: rem(10);
  margin-top: rem(4);
}

.channelImg {
  width: rem(20);
  height: rem(20);
  border-radius: rem(10);
  margin-right: rem(8);
}

.menu {
  @include list-unstyled(); // Bootstrap mixin
}

.menuItem {
  border-top: $border-width solid $silver;

  &:last-child {
    padding-bottom: 0;
  }
}

.menuItemInner {
  @extend .itemInner;
  display: block;
  width: 100%;
  text-align: left;

  svg {
    margin-right: rem(8);
  }
}

.btnLogout {
  @include links($orange);
}
