@import '../../../styles/core';

.root {
  position: relative;
  margin-bottom: rem(28);

  @include media-breakpoint-up($header-search-form-breakpoint) {
    width: rem(240);
    margin-bottom: 0;
  }
}

.inputWrap {
  width: 100%;

  input {
    position: relative;
    height: rem(48);
    padding: rem(6) rem(40) rem(6) $input-padding-x;
    border-radius: 50rem;
    font-size: $font-size-sm;
    color: $white;

    @include media-breakpoint-up($header-search-form-breakpoint) {
      height: rem(32);
    }

    &,
    &:focus {
      border-color: rgba($white, 0.1);
      background-color: rgba($white, 0.1);
      color: $white;

      @include media-breakpoint-up($header-search-form-breakpoint) {
        border-color: rgba($primary, 0.1);
        background-color: rgba($primary, 0.1);
        color: currentColor;
      }
    }
  }
}

.btnSubmit {
  @include position(absolute, $top: 50%, $right: rem(10));
  padding: 6px;
  transform: translateY(-50%);
  color: $white;

  @include media-breakpoint-up($header-search-form-breakpoint) {
    color: inherit;
  }

  svg {
    float: left;
    width: rem(24);
    height: rem(24);

    @include media-breakpoint-up($header-search-form-breakpoint) {
      width: rem(16);
      height: rem(16);
    }
  }
}
