@import '../../../../styles/core';

.root {
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
  padding: rem(60);
  border-radius: rem(8);
  background-color: white;
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.25);
}

.title {
  font-size: rem(24);
  margin-bottom: rem(40);
}

.form {
  display: grid;
  row-gap: rem(24);
  margin-bottom: rem(32);

  input {
    border-radius: $border-radius;
  }
}

.submitButton {
  height: rem(48);
  margin-top: rem(16);
}

.linkButton {
  margin-left: rem(4);
  text-decoration: underline;
}
