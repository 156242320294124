@import '../../../styles/core';

.sliderWrapper {
  :global(.slick-arrow) {
    z-index: 9;
    width: auto;
    height: auto;
    transition: opacity $transition-duration;

    &:hover {
      opacity: $hover-opacity;
    }

    &::before {
      opacity: 1;
    }
  }

  :global(.slick-prev) {
    top: 100%;
    left: 50%;
    transform: translate(calc(-100% - #{rem(30)}), -100%);

    @include media-breakpoint-up($bihada-factory-mission-slider-breakpoint) {
      top: 50%;
      left: 0;
      transform: none;
    }

    @include media-breakpoint-up(md) {
      left: rem(112);
    }

    &::before {
      content: url('../../../assets/icons/arrow-left.svg');
    }
  }

  :global(.slick-next) {
    top: 100%;
    right: 50%;
    transform: translate(calc(100% + #{rem(30)}), -100%);

    @include media-breakpoint-up($bihada-factory-mission-slider-breakpoint) {
      top: 50%;
      right: 0;
      transform: none;
    }

    @include media-breakpoint-up(md) {
      right: rem(112);
    }

    &::before {
      content: url('../../../assets/icons/arrow-right.svg');
    }
  }

  :global(.slick-list) {
    margin: ($bihada-factory-mission-slide-shadow-offset * -1)
      ($bihada-factory-mission-card-shadow-blur * -1) 0;

    @include media-breakpoint-up($bihada-factory-mission-slider-breakpoint) {
      margin: ($bihada-factory-mission-slide-shadow-offset * -1) 0;
    }
  }
}

.slide {
  padding: $bihada-factory-mission-slide-shadow-offset
    $bihada-factory-mission-card-shadow-blur rem(64);

  @include media-breakpoint-up($bihada-factory-mission-slider-breakpoint) {
    padding: $bihada-factory-mission-slide-shadow-offset 0;
  }
}

.card {
  max-width: calc(100% - rem(120));
  margin: 0 auto;
  border-radius: 0 0 rem(32) rem(32);
  background-color: $white;
  // box-shadow: 0 $bihada-factory-mission-card-shadow-y
  //   $bihada-factory-mission-card-shadow-blur (rem(4) * -1) rgba($black, 0.15);

  @include media-breakpoint-up(md) {
    max-width: calc(100% - rem(480));
    border-radius: 0 0 rem(48) rem(48);
  }
}

.cardContent {
  padding: 0 rem(32) rem(16);
  font-family: $font-family-secondary;
  font-size: $font-size-sm;
  font-weight: $font-weight-black;
  line-height: 2;
  white-space: pre-line;

  @include media-breakpoint-up(md) {
    padding-bottom: rem(32);
    font-size: $font-size-base;
  }
}

.cardNum {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: rem(48);
  height: rem(48);
  margin-top: rem(16) * -1;
  margin-bottom: rem(8);
  border: 1px solid $off-black-translucent;
  border-radius: 50%;
  color: $off-black-translucent;
  font-size: rem(28);

  @include media-breakpoint-up(md) {
    width: rem(72);
    height: rem(72);
    margin-top: 0;
    margin-bottom: rem(16);
    font-size: rem(32);
  }
}
