@import '../../styles/core';

.img {
  @include aspect-ratio-img(304, 208, contain);
  margin-bottom: rem(16);
}

.meta {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: rem(8);
  }

  > *:not(:last-child) {
    margin-right: rem(8);
  }
}

.date {
  @include font-en-condensed;
  font-size: $font-size-sm;
}

.title {
  margin-bottom: 0;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;

  a {
    @include truncation(2);
  }
}
