@import '../../styles/core';

.input-label {
  display: flex;
  align-items: center;
  font-weight: $font-weight-bold;
  margin-bottom: rem(8);

  &.input-label-sm {
    font-size: $font-size-sm;
  }

  &.input-label-lg {
    font-size: $font-size-lg;
  }
}

.input-badge {
  margin-right: rem(8);
  padding: rem(5) rem(8);
  border-radius: $border-radius-sm;
  font-size: rem(10);
  line-height: 1;

  &.input-badge-required {
    background-color: $pink;
    color: $white;
  }

  &.input-badge-optional {
    background-color: $whitesmoke;
    color: $gray;
  }
}

.input {
  font-size: $font-size-base;
  &::placeholder {
    text-overflow: ellipsis;
  }
}

.invalid-feedback {
  font-weight: $font-weight-bold;
}
