@import '../../../../styles/core';

.inner {
  padding: rem(60) rem(64);
}

.title {
  margin-bottom: rem(40);
}

.form {
  display: grid;
  row-gap: rem(24);

  input {
    background-color: white;
    border: 1px solid $silver;
  }
}

.note {
  margin-top: rem(8) * -1;
  font-size: $font-size-sm;

  // a {
  //   @include links($text-decoration: underline);
  // }
}

.btnSubmit {
  margin-top: rem(16);
}
