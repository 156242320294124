@import '../../styles/core';

.root {
  background-color: $whitesmoke;
}

.hero {
  height: rem(160);
  background-size: cover;
  background-position: center;

  @include media-breakpoint-up(sm) {
    height: rem(240);
  }

  @include media-breakpoint-up($page-layout-breakpoint) {
    height: rem(320);
  }
}

.content {
  margin-top: rem(40) * -1;

  @include media-breakpoint-up(sm) {
    margin-top: rem(64) * -1;
  }

  @include media-breakpoint-up($page-layout-breakpoint) {
    margin-top: rem(80) * -1;
  }
}

.header {
  padding: rem(24) rem(16);
  border-radius: $border-radius-lg;
  background-color: $white;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding: rem(32);
  }

  @include media-breakpoint-up($page-layout-breakpoint) {
    padding: rem(48);
  }

  p {
    white-space: pre-line;
  }
}

.description {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;

  @include media-breakpoint-up(md) {
    font-size: $font-size-base;
  }
}
