@import '../../styles/core';

.section {
  display: flex;
  @include responsive(padding, $section-padding-spacers);
  text-align: center;

  @include media-breakpoint-up($home-section-breakpoint) {
    text-align: left;
  }

  &.acropass {
    background-image: url('../../assets/images/home-acropass-bg-01.jpg');
    background-size: cover;
    background-position: 30%;

    @include media-breakpoint-up(lg) {
      background-position: 80%;
    }
  }

  &.karadaArrange {
    background-image: url('../../assets/images/home-karada-arrange-bg-01.jpg');
    background-size: cover;
    background-position: 25%;

    @include media-breakpoint-up(sm) {
      background-position: 5%;
    }

    @include media-breakpoint-up($home-section-breakpoint) {
      background-position: center;
    }
  }
}

.sectionText {
  grid-row-end: -1;
  grid-column-end: -1;
  font-size: rem(18);
  font-weight: $font-weight-bold;
  line-height: $line-height-sm;

  @include media-breakpoint-up(md) {
    font-size: rem(52);
  }

  svg {
    margin-bottom: rem(24);

    @include media-breakpoint-up(md) {
      display: block;
      width: auto;
    }
  }
}

.acropassContent {
  display: grid;
  grid-template-rows: 1.95fr 1fr;
  position: relative;
  width: 100%;
  padding: 0 $gutter-width;

  @include media-breakpoint-up($home-section-breakpoint) {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1.5fr;
    align-content: center;
    column-gap: rem(8);
    padding: 0 rem(24);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr auto;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 1fr 1.5fr;
    column-gap: rem(32);
  }
}

.acropassText {
  color: $yellow;

  @include media-breakpoint-up(lg) {
    width: rem(728);
    margin: 0 auto;
  }

  svg {
    @include media-breakpoint-up(md) {
      height: 0.85em;
    }

    @include media-breakpoint-up(lg) {
      margin-left: auto;
      margin-right: rem(16);
    }
  }
}

.acropassDescription {
  margin-bottom: rem(32);

  br {
    @include media-breakpoint-up($home-section-breakpoint) {
      display: none;
    }
  }
}

.acropassCharacter {
  position: absolute;

  &.acropassNidoru {
    @include position(null, $top: 0, $right: 10%);
    width: 20%;
    max-width: rem(132);
  }

  &.acropassHiaru {
    @include position(null, $left: 50%, $bottom: rem(-16));
    width: 22%;
    max-width: rem(140);
  }
}

.karadaArrangeContent {
  display: grid;
  grid-template-rows: 1fr auto;
  position: relative;
  width: 100%;

  @include media-breakpoint-up($home-section-breakpoint) {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1.05fr;
    align-content: center;
    padding: 0 rem(24);
  }
}

.karadaArrangeText {
  color: $skyblue;

  @include media-breakpoint-up($home-section-breakpoint) {
    min-width: rem(520);
  }

  @include media-breakpoint-up(xl) {
    margin: 0 auto;
    padding-right: rem(24);
  }

  svg {
    @include media-breakpoint-up(md) {
      height: 0.74em;
    }

    path {
      @include media-breakpoint-up($home-section-breakpoint) {
        fill: $white;
      }
    }
  }
}

.karadaArrangeCharacter {
  position: absolute;

  &.karadaArrangeIkumi {
    @include position(null, $left: 2%, $bottom: rem(96));
    width: 20%;
    max-width: rem(132);
    transform: rotateY(180deg) rotate(-20deg);

    @include media-breakpoint-up(md) {
      @include position(null, $left: 30%, $bottom: rem(-24));
    }
  }

  &.karadaArrangeMamoru {
    @include position(null, $right: 5%, $bottom: rem(8));
    width: 22%;
    max-width: rem(140);
  }
}
