@import '../../../../styles/core';

.root {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: rem(16);
  border-radius: rem(8);
  width: 100%;

  .form {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: rem(24);
    width: 100%;

    .container:last-child {
      display: grid;
      gap: rem(16);
    }

    .rowContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .select {
      margin-right: rem(24);
      width: 100%;
    }

    input {
      background-color: white;
      border: 1px solid $silver;

      &:disabled {
        background-color: $whitesmoke;
      }
    }
  }
}
