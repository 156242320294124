@import '../../styles/core';

.dropzone {
  @include aspect-ratio(140, 100, null);
  // max-width: rem(400);
  border: $border-width solid #cccccc;
}

.dropzoneInvalid {
  border-color: $red;
  // @include focus-box-shadow($red);
}

@each $state, $color in $dropzone-colors {
  // $state: dragActive, dragAccept, dragReject
  .#{$state} {
    border-color: rgba($color, 0.25);
    // @include focus-box-shadow($color);
  }
}

.imgIcon {
  @include position(absolute, 50%, 50%);
  transform: translate(-50%, -50%);
  color: $light-silver;
  font-size: rem(40);
}

.preview {
  @include aspect-ratio-img(186, 148);
  max-width: rem(186);
}

.actionBtns {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  column-gap: rem(8);
  margin-top: rem(6);

  button {
    // @include links($text-decoration: underline);
  }
}
