@import '../../../styles/core';

// Nav menu (wrapper)
.root {
  @include position(absolute, 100%, 0);
  width: 100%;
  height: calc(100vh - #{rem(66)}); // window height - header height
  padding: rem(24);
  background-color: $primary;
  opacity: 0;
  visibility: hidden;
  overflow-y: scroll;
  transition: opacity 0.5s, visibility 0.5s;

  @include media-breakpoint-up($header-breakpoint) {
    position: static;
    width: auto;
    height: auto !important;
    padding: 0;
    background-color: transparent;
    opacity: 1;
    visibility: visible;
    transform: none;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.searchForm {
  @include media-breakpoint-up($header-search-form-breakpoint) {
    display: none;
  }
}

.navContent {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(rem(192), 1fr));
  gap: rem(48) rem(32);
  @include list-unstyled(); // Bootstrap mixin
  width: 91%;
  margin: 0 auto;
  border-radius: rem(8);
  background-color: $primary;
  color: $white;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, auto);
    justify-content: space-evenly;
    width: auto;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, auto);
    padding: rem(36) rem(48) rem(40);
  }

  @include media-breakpoint-up($header-breakpoint) {
    grid-auto-flow: column;
    grid-template-columns: auto;
    column-gap: rem(16);
    padding: 0;
    background-color: transparent;
    color: inherit;
  }

  a {
    &:hover {
      color: currentColor;
      opacity: $header-hover-opacity;
    }
  }
}

.menuTitle {
  display: flex;
  align-items: center;
  font-weight: $font-weight-bold;

  @include media-breakpoint-up($header-breakpoint) {
    padding: rem(5) 0;
    font-size: $font-size-sm;
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-bottom: rem(16);

    @include media-breakpoint-up($header-breakpoint) {
      margin-bottom: 0;
    }
  }

  svg {
    margin-right: rem(8);
    width: rem(16);
    height: rem(16);

    @include media-breakpoint-up($header-breakpoint) {
      display: none;
    }
  }
}

.menuWrap {
  display: grid;
  row-gap: rem(16);
  margin-left: rem(16 + 8);
  background-color: $primary;
  color: $white;

  @include media-breakpoint-up($header-breakpoint) {
    @include position(absolute);
    margin-left: 0;
    padding: rem(16);
    border-radius: rem(8);
    opacity: 0;
    visibility: hidden;
    transition: opacity $transition-duration, visibility $transition-duration;

    .navItem.active & {
      opacity: 1;
      visibility: visible;
    }
  }
}

.menuSubtitle {
  display: block;
  margin-bottom: rem(16);
  font-weight: $font-weight-bold;
  opacity: 0.3;
}

.menuList {
  display: grid;
  row-gap: rem(16);
  @include list-unstyled(); // Bootstrap mixin
  font-size: $font-size-sm;

  .menuSubtitle ~ & {
    margin-left: rem(16);
  }
}
