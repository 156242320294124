@import '../../styles/core';

.root {
  /* Make sure the inner table is always as wide as needed */
  width: 100%;
  border-spacing: 0;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid $light-silver;
  }
}
