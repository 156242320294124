@import '../../../styles/core';

.root {
  position: relative;
  z-index: 999;
  grid-area: header;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $header-navbar-padding-y $header-navbar-padding-x;
}

.titleWrap {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  column-gap: 1rem;
}

.titleText {
  line-height: 1.1;
  margin-bottom: rem(8);

  .titleSm {
    font-size: $font-size-base;
  }

  .subtitle {
    font-size: rem(13);
  }
}

.navItems {
  @include list-unstyled(); // Bootstrap mixin
  display: grid;
  grid-auto-flow: column;
  column-gap: rem(24);
  margin-bottom: 0;

  > li {
    display: flex;
    position: relative;
  }
}

.notification {
  display: flex;

  svg {
    font-size: rem(28);
    align-self: center;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(24);
    height: rem(24);
    margin-left: rem(-12);
    border-radius: 50%;
    background-color: $orange;
    color: $white;
    font-size: rem(14);
  }
}
