@import '../../styles/core';

.root {
  @include responsive(padding, $section-padding-lg-spacers);
}

.button {
  color: $purple;
  border-bottom: 1px solid $purple;
}

.title {
  @extend %mb-xl;
  @extend %title;
  text-align: center;
}

.description {
  @extend %mb-lg;
  font-size: $font-size-sm;
}

.form {
  display: grid;
  row-gap: rem(24);

  @include media-breakpoint-up($contact-breakpoint) {
    row-gap: rem(48);
  }
}

.inputWrap {
  @include media-breakpoint-up($contact-breakpoint) {
    display: grid;
    grid-template-columns: 1fr 2.267fr;
  }

  :global(.input-label) {
    @include media-breakpoint-up($contact-breakpoint) {
      align-self: start;
      margin-top: calc(#{$input-padding-y} + #{$input-border-width});
    }
  }
}

.btnSubmitWrap {
  grid-column: 1 / -1;
  margin-top: rem(16);
  text-align: center;

  @include media-breakpoint-up($contact-breakpoint) {
    margin-top: rem(32);
  }
}

.error {
  width: 100%;
  text-align: center;
  color: $pink;
  font-weight: bold;
}
