@import '../../../styles/core';

.sliderWrapper {
  overflow: hidden;

  div {
    height: 100%;
  }
}

:global(.slick-slide) {
  font-size: 0;
}

.slide {
  position: relative;
  min-height: rem(480);

  img {
    @include position(absolute, 0, 0);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.sliderDots {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  @include position(absolute, $bottom: rem(8));
  @include list-unstyled(); // Bootstrap mixin
  width: 100%;
  padding: 0 rem(8);

  > li {
    margin: rem(4);
    font-size: 0;
    line-height: 0;

    &:global(.slick-active) {
      button {
        &::before {
          background-color: $secondary;
        }
      }
    }

    button {
      padding: rem(4);

      &:hover {
        &::before {
          background-color: $secondary;
        }
      }

      &::before {
        display: block;
        width: rem(8);
        height: rem(8);
        border-radius: 50%;
        background-color: $purple-translucent;
        transition: background-color $transition-duration;
        content: '';
      }
    }
  }
}

.sliderThumb {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, rem(48));
  gap: rem(12);
  @include position(absolute, null, rem(32), rem(32), rem(32));
  @include list-unstyled(); // Bootstrap mixin

  > li {
    @include aspect-ratio-img(1, 1);

    &:global(.slick-active) {
      button {
        &::after {
          background-color: transparent;
        }
      }
    }

    &:not(:global(.slick-active)) {
      button {
        &:hover {
          &::after {
            background-color: rgba($off-black, 0.3);
          }
        }
      }
    }

    button {
      display: block;

      &::after {
        @include position(absolute, 0, 0, 0, 0);
        background-color: $off-black-translucent;
        transition: background-color $transition-duration;
        content: '';
      }
    }
  }
}
