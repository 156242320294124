@import '../../styles/core';

.root {
  @include responsive(padding, $section-padding-lg-spacers);
  font-size: $font-size-sm;
}

.title {
  @extend %mb-xl;
  @extend %title;
  text-align: center;
}

.description,
.section {
  &:not(:last-child) {
    margin-bottom: rem(32);

    @include media-breakpoint-up(md) {
      margin-bottom: rem(48);
    }
  }
}

.sectionTitle {
  margin-bottom: rem(16);
  font-size: rem(18);

  @include media-breakpoint-up(md) {
    margin-bottom: rem(24);
    font-size: $font-size-lg;
  }
}

.ol {
  @include list-unstyled(); // Bootstrap mixin

  li {
    &::before {
      content: attr(data-number);
    }
  }

  p {
    margin: rem(8);
  }
}

.ul {
  @include list-unstyled(); // Bootstrap mixin
}

.btnLink {
  margin-bottom: rem(16);

  @include media-breakpoint-up(md) {
    margin-bottom: rem(24);
  }
}
