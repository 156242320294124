@import '../../styles/core';

.content {
  padding-top: rem(40);
  padding-bottom: rem(40);
  padding-left: calc((#{$timeline-dot-size} - #{$timeline-border-width}) / 2);

  @include media-breakpoint-up(sm) {
    padding-left: rem(32);
    padding-right: rem(24);
  }

  @include media-breakpoint-up(md) {
    padding-top: rem(80);
    padding-bottom: rem(80);
    padding-left: rem(80);
    padding-right: rem(32);
  }

  @include media-breakpoint-up(lg) {
    padding-left: rem(112);
    padding-right: rem(72);
  }

  @include media-breakpoint-up(xl) {
    padding-left: rem(192);
    padding-right: rem(152);
  }
}

.timeline {
  @include list-unstyled(); // Bootstrap mixin
}

.timelineItem {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: auto 1fr;
  padding-top: rem(16);
  border-left: $timeline-border-width solid $timeline-border-color;

  @include media-breakpoint-up($timeline-breakpoint) {
    grid-template-rows: auto;
    grid-template-columns: auto auto 1fr;
    padding-top: rem(24);
  }

  // Set padding-top: 0 if parent ul(.timeline) is not first-child
  .timeline:not(:first-child) &:first-child {
    padding-top: 0;
  }

  // Set longer padding-top if parent ul(.timeline) is first-child
  .timeline:first-child &:first-child {
    padding-top: rem(28);
  }

  // Dot
  &::before {
    display: block;
    width: $timeline-dot-size;
    height: $timeline-dot-size;
    margin-right: calc(
      #{rem(16)} - (#{$timeline-dot-size} - #{$timeline-border-width}) / 2
    );
    border-radius: 50%;
    background-color: $secondary;
    content: '';
    // Adjust dot position
    @each $breakpoint, $font-size in $timeline-text-font-sizes {
      @include media-breakpoint-up($breakpoint) {
        transform: translate(
          $timeline-dot-translate-x,
          ($font-size * $line-height-base - $timeline-dot-size) / 2
        );
      }
    }

    @include media-breakpoint-up($timeline-breakpoint) {
      margin-right: calc(
        #{$timeline-year-inner-size} / 2 - #{$timeline-border-width} - #{$timeline-dot-size}
      );
    }
  }
}

.timelineMonth {
  margin-right: rem(16);
  @include font-en-condensed(0.17em);
  font-size: $font-size-sm;
  // Same line height as .timelineContent
  @each $breakpoint, $font-size in $timeline-text-font-sizes {
    @include media-breakpoint-up($breakpoint) {
      line-height: $font-size * $line-height-base;
    }
  }
}

.timelineContent {
  grid-column-end: -1;
  @include responsive(font-size, $timeline-text-font-sizes);
  white-space: pre-line;
}

.timelineYear {
  display: flex;
  align-items: center;
  position: relative;
  padding: rem(40) 0;
  border-left: $timeline-border-width solid $timeline-border-color;

  @include media-breakpoint-up($timeline-breakpoint) {
    padding: rem(28) 0;
  }

  &:last-child {
    padding-bottom: 0;

    &::after {
      display: block;
      @include position(
        absolute,
        $left: $timeline-border-width * -1,
        $bottom: 0
      );
      height: calc(
        (#{$timeline-year-inner-size} - #{$timeline-border-width}) / 2
      );
      border-left: $timeline-border-width solid $whitesmoke;
      content: '';

      @include media-breakpoint-up($timeline-breakpoint) {
        display: none;
      }
    }
  }

  &::before {
    display: block;
    width: rem(88);
    border-bottom: $timeline-border-width solid $timeline-border-color;
    content: '';

    @include media-breakpoint-up($timeline-breakpoint) {
      display: none;
    }
  }
}

.timelineYearInner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $timeline-year-inner-size;
  height: $timeline-year-inner-size;
  border: $timeline-border-width solid $timeline-border-color;
  border-radius: 50%;
  background-color: $whitesmoke;
  @include font-en-condensed();
  font-size: $font-size-lg;
  text-indent: $letter-spacing-en;

  @include media-breakpoint-up($timeline-breakpoint) {
    transform: translateX($timeline-dot-translate-x);
  }
}
