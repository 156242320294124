@import '../../../styles/core';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-top: 2px solid black;
  padding: rem(30);
  margin-bottom: rem(50);

  .title {
    font-family: $font-family-base;
    font-weight: bold;
    font-size: rem(36);
    margin-bottom: rem(80);
    text-align: center;
  }

  .container {
    display: grid;
    grid-template-columns: minmax(0, rem(134));
    justify-content: center;
    gap: rem(32) rem(64);
    @include list-unstyled(); // Bootstrap mixin
    grid-template-columns: repeat(2, 1fr);

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(3, 1fr);
      gap: rem(80) rem(32);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(auto-fit, minmax(0, rem(224)));
    }
  }
}
