@import '../../../styles/core';

.root {
  grid-area: sidebar;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: auto 1fr;
  min-width: rem(200);
  background-color: $primary;
  color: $white;
  overflow-y: scroll;
}

.icons {
  @include list-unstyled(); // Bootstrap mixin
  display: grid;
  align-content: start;
  row-gap: rem(24);
  margin-bottom: 0;
  padding: rem(68) rem(14) rem(24);
}

.icon {
  a,
  button {
    display: block;
    width: rem(40);
    border: $border-width solid $white;
    border-radius: $border-radius;
    overflow: hidden;
    transition: box-shadow $transition-duration;

    &:hover {
      box-shadow: 0 0 0 rem(4) rgba($white, 0.3);
    }
  }

  a {
    @include aspect-ratio-img();

    &.active {
      box-shadow: 0 0 0 rem(4) rgba($white, 0.4);
    }
  }

  button {
    position: relative;
    padding-top: calc(100% - #{$border-width} * 2);

    svg {
      @include position(absolute, 50%, 50%);
      transform: translate(-50%, -50%);
    }
  }
}

.settings {
  justify-self: center;
  padding: rem(16) rem(16) $main-gap-sm;

  &:hover {
    color: currentColor;
    opacity: 0.6;
  }

  svg {
    font-size: rem(24);
  }
}

.navItems {
  grid-row: 1 / -1;
  grid-column-end: -1;
  @include list-unstyled(); // Bootstrap mixin
  margin-bottom: 0;
  padding: rem(64) 0 $main-gap-sm;
  border-left: $border-width solid rgba($white, 0.4);
}

.navItem {
  a {
    display: block;
    padding: rem(12) rem(32);

    &:hover {
      color: currentColor;
      background-color: rgba($white, 0.2);
    }

    &.active {
      background-color: rgba($white, 0.3);
    }

    svg {
      margin-right: rem(12);
    }
  }
}

.workspaceActive {
  box-shadow: 0 0 0 rem(4) rgba($white, 0.4);
}

.unread {
  display: inline-block;
  width: rem(12);
  height: rem(12);
  background-color: $orange;
  border-radius: rem(6);
  margin-left: rem(16);
}
