@import '../../../styles/core';

.root {
  height: rem(24);
  font-size: rem(10);
  padding: rem(4) rem(12);
  margin: rem(16);
  font-family: $font-family-base;
  font-weight: bold;
  background-color: $whitesmoke;
  color: $gray;
  border-radius: rem(12);
  line-height: rem(10);

  @include media-breakpoint-down(sm) {
    margin: rem(4) rem(8);
  }
}

.active {
  background-color: $purple;
  color: white;
}
