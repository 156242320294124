/**
 * ----------------------------------------
 * Typography
 * ----------------------------------------
 */

%title {
  display: block;
  @include responsive(font-size, $title-font-sizes);
}

%title-lg {
  display: block;
  @include responsive(font-size, $title-lg-font-sizes);
}

/**
 * ----------------------------------------
 * Spacing
 * ----------------------------------------
 */

%mb-sm {
  @include responsive(margin-bottom, $margin-bottom-sm-spacers);
}

%mb-md {
  @include responsive(margin-bottom, $margin-bottom-md-spacers);
}

%mb-lg {
  @include responsive(margin-bottom, $margin-bottom-lg-spacers);
}

%mb-xl {
  @include responsive(margin-bottom, $margin-bottom-xl-spacers);
}
