@import '../../styles/core';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: rem(8);
}

.text {
  font-size: 11;
  display: flex;
  height: auto;
  margin-bottom: 5;
}

.mandatory {
  color: orange;
  margin-left: 3;
}

.editor {
  flex: 1;
}

.editorError {
  border: 1px solid $pink;
}

.error {
  color: $pink;
  font-size: rem(14);
  margin-top: rem(8);
  font-weight: bold;
}

.ql-editor {
  min-height: rem(160);
}

.ql-hidden {
  display: none;
}

.ql-tooltip {
  left: 0 !important;
}

.ql-editor img {
  max-width: 100%;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  color: lightgray !important;
  opacity: 0.4 !important;
  font-size: rem(15);
  font-style: normal;
}

.ql-container {
  font-size: rem(15) !important;
}

.ql-toolbar.ql-snow {
  border-color: #d6dade !important;
}
