@import '../acropassBrandStory/acropassBrandStory.module.scss';

/* ----- Hero ----- */
.hero {
  background-image: url('../../assets/images/karada-arrange-brand-story-top-01.jpg');
  color: $white;

  svg {
    filter: drop-shadow(0 0 rem(10) $black);
  }
}

.heroTitle {
  @include media-breakpoint-up(md) {
    width: rem(640);
  }
}

.heroDescription {
  text-shadow: 0 0 rem(10) $black;
}

/* ----- Our Vision ----- */
.visionSection {
  background-size: cover;
  background-position: center;
  background-color: $light-skyblue;
}

.visionItem {
  &:nth-child(even) {
    .visionItemTitle {
      background-color: $bright-skyblue;
    }
  }
}

/* ----- Brand Identity ----- */
.identityContent {
  gap: rem(24) rem(80);

  @include media-breakpoint-up(lg) {
    column-gap: rem(192);
  }
}

.identityItem {
  row-gap: rem(8);

  @include media-breakpoint-up($brand-story-identity-breakpoint) {
    row-gap: rem(16);
  }
}

.identityItemLogo {
  @include media-breakpoint-up(md) {
    width: auto;
    height: rem(32);
  }
}

.identityCharacter {
  @include position(absolute, 50%);
  z-index: -1;
  transform: translateY(-35%);

  &.identityIkumi {
    left: rem(4);
    width: 24.5%;

    @include media-breakpoint-up($brand-story-identity-breakpoint) {
      left: rem(-52);
      width: rem(136);
    }

    @include media-breakpoint-up(lg) {
      left: rem(-24);
    }
  }

  &.identityMamoru {
    right: rem(16);
    width: 22.5%;

    @include media-breakpoint-up($brand-story-identity-breakpoint) {
      right: rem(-48);
      width: rem(140);
    }

    @include media-breakpoint-up(lg) {
      right: rem(-32);
    }
  }
}

/* ----- Our Policy ----- */
.policyItem {
  button {
    &.active {
      background-color: $skyblue;
    }
  }
}

.policyText {
  max-width: rem(576);
  margin: 0 auto;
}

.policySubtitle {
  background-image: none;
}

.essenceDescription {
  position: relative;
  justify-self: center;
  @include underline(rem(8), $bright-skyblue);
  font-size: rem(20);
  font-weight: $font-weight-bold;

  @include media-breakpoint-up($brand-story-identity-breakpoint) {
    @include underline(rem(16), $bright-skyblue);
    font-size: rem(36);
  }
}
