@import '../../styles/core';

.section {
  display: flex;
  min-height: calc(100vh - #{$header-fallback-height});
  @include responsive(padding, $section-padding-spacers);
  text-align: center;
}

.sectionTitle {
  margin-bottom: rem(32);
  font-size: rem(20);
  @include font-en();

  @include media-breakpoint-up(md) {
    margin-bottom: rem(48);
    font-size: rem(24);
  }
}

.container {
  display: grid;
  align-items: center;
}

/* ----- Hero ----- */
.hero {
  display: flex;
  min-height: calc(100vh - #{$header-fallback-height});
  padding: rem(64) 0 rem(32);
  background-image: url('../../assets/images/acropass-brand-story-top-01.jpg');
  background-size: cover;
  background-position: center;
  text-align: center;

  @include media-breakpoint-up($bihada-factory-hero-breakpoint) {
    padding: rem(152) 0 rem(56);
  }
}

.heroContainer {
  display: grid;
  align-content: space-evenly;
  justify-items: center;
  row-gap: rem(40);

  @include media-breakpoint-up($brand-story-hero-breakpoint) {
    row-gap: rem(60);
  }
}

.heroTitle {
  width: rem(216);
  height: auto;

  @include media-breakpoint-up(md) {
    width: rem(560);
  }
}

.heroDescription {
  margin-bottom: rem(56);
  font-size: rem(32);
  line-height: $line-height-sm;

  @include media-breakpoint-up(md) {
    margin-bottom: rem(68);
    font-size: rem(36);
  }

  br {
    &:nth-child(odd) {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}

.heroText {
  width: 86.2%;
  max-width: rem(320);
  height: auto;
  align-self: end;

  @include media-breakpoint-up(md) {
    max-width: rem(412);
  }
}

/* ----- Our Vision ----- */
.visionSection {
  // background-image: url('../../assets/images/ac-brand-story1.jpg');
  background-size: cover;
  background-position: center;
  background-color: $light-orange;
}

.visionSubtitle {
  @extend .sectionTitle;
  text-transform: uppercase;
}

.visionContent {
  @include position(relative, $left: 50%);
  width: 100vw;
  margin-left: -50vw;
  overflow-x: scroll;

  @include media-breakpoint-up(sm) {
    align-self: start;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: center;
    left: auto;
    width: auto;
    margin-left: 0;
  }
}

.visionContentInner {
  display: flex;
  @include list-unstyled();
  padding: 0 $brand-story-vision-content-inner-padding-x;
  width: calc(
    (100% - #{$brand-story-vision-content-inner-padding-x} * 2) * 3 - #{$brand-story-vision-item-offset} *
      2
  );
  max-width: 1040px;
  box-sizing: content-box;

  @include media-breakpoint-up(sm) {
    width: auto;
    padding: 0 rem(24);
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
    padding: 0;
  }
}

.visionItem {
  width: 100%;
  white-space: pre-line;

  &:nth-child(even) {
    position: relative;
    z-index: 1;
    margin: 0 ($brand-story-vision-item-offset * -1);
  }
}

.visionItemTitle {
  margin-bottom: rem(12);
  @include font-en(0);
  font-size: rem(24);
  width: 100%;
  border-radius: 50%;
  background-color: $white;
  @include aspect-ratio(1, 1, span) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(16);
  }

  @include media-breakpoint-up(md) {
    width: rem(240);
    margin-bottom: rem(24);
  }

  @include media-breakpoint-up(lg) {
    width: rem(320);
    font-size: rem(32);
  }

  // Set the following styles if parent's numeric position is even
  .visionItem:nth-child(even) & {
    background-color: $yellow;
  }
}

.visionDescription {
  position: relative;
  justify-self: center;
  font-size: rem(20);
  font-weight: $font-weight-bold;

  @include media-breakpoint-up($brand-story-identity-breakpoint) {
    font-size: rem(36);
  }
}

.visionItemDescription {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
}

.visionIndicators {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  column-gap: rem(16);
  @include list-unstyled(); // Bootstrap mixin
  margin-top: rem(16);

  @include media-breakpoint-up(sm) {
    display: none;
  }

  li {
    width: rem(8);
    height: rem(8);
    border-radius: 50%;
    background-color: $purple-translucent;

    &.active {
      background-color: $secondary;
    }
  }
}

/* ----- Brand Identity ----- */
.identityContent {
  display: grid;
  justify-content: center;
  gap: rem(16) rem(80);
  position: relative;
  margin-bottom: rem(32);

  @include media-breakpoint-up($brand-story-identity-breakpoint) {
    grid-auto-flow: column;
    margin-bottom: rem(56);
  }
}

.identityItem {
  display: grid;
  justify-items: center;
  row-gap: rem(4);
  text-align: left;

  @include media-breakpoint-up($brand-story-identity-breakpoint) {
    justify-items: center;
    row-gap: rem(16);
  }
}

.identityItemImg {
  width: rem(80);

  @include media-breakpoint-up($brand-story-identity-breakpoint) {
    width: $brand-story-identity-item-img-size;
  }

  img {
    width: 100%;
  }
}

.identityItemTitle {
  align-self: end;
  font-size: rem(21);
  @include font-en();
  line-height: $line-height-sm;

  @include media-breakpoint-up(md) {
    font-size: rem(36);
  }
}

.identityItemDescription {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
}

.identityPlus {
  align-self: start;
  justify-self: center;
  width: rem(40);

  @include media-breakpoint-up($brand-story-identity-breakpoint) {
    transform: translateY(
      calc(#{$brand-story-identity-item-img-size} / 2 - 50%)
    );
  }

  img {
    width: 100%;
  }
}

.identityCharacter {
  @include position(absolute, 50%);
  z-index: -1;
  transform: translateY(-35%);

  &.identityHiaru {
    left: rem(4);
    width: 24.5%;

    @include media-breakpoint-up($brand-story-identity-breakpoint) {
      left: rem(-68);
      width: rem(160);
    }

    @include media-breakpoint-up(lg) {
      left: rem(-8);
    }
  }

  &.identityNidoru {
    right: rem(16);
    width: 22.5%;

    @include media-breakpoint-up($brand-story-identity-breakpoint) {
      right: rem(-48);
      width: rem(140);
    }

    @include media-breakpoint-up(lg) {
      right: rem(8);
    }
  }
}

.identityDescription {
  position: relative;
  justify-self: center;
  @include underline();
  font-size: rem(20);
  font-weight: $font-weight-bold;

  @include media-breakpoint-up($brand-story-identity-breakpoint) {
    @include underline(rem(16));
    font-size: rem(36);
  }
}

/* ----- Our Policy ----- */
.policySection {
  background-image: url('../../assets/images/ac-brand-story2.jpg');
  background-size: cover;
  background-position: center;
}

.policyContent {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, minmax(rem(80), rem(200)));
  justify-content: center;
  gap: rem(16);
  margin: 0 rem(16) rem(16);
  @include list-unstyled(); // Bootstrap mixin

  @include media-breakpoint-up($brand-story-policy-breakpoint) {
    grid-template-rows: auto;
    grid-template-columns: repeat(4, auto);
    align-items: start;
    gap: 0;
    margin: 0 0 rem(48);
  }
}

.policyItem {
  @include aspect-ratio(1, 1, button);

  @include media-breakpoint-up($brand-story-policy-breakpoint) {
    width: rem(208);

    &:first-child {
      margin-top: rem(16);
    }

    &:not(:first-child) {
      margin-left: rem(48) * -1;
    }

    &:nth-child(even) {
      margin-top: rem(96);
    }
  }

  @include media-breakpoint-up(lg) {
    width: rem(240);
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(8);
    border-radius: 50%;
    background-color: $off-black-translucent;
    color: $white;
    @include font-en(0);
    font-size: rem(18);
    white-space: pre-line;
    transition: background-color $transition-duration,
      color $transition-duration;

    @include media-breakpoint-up(md) {
      font-size: rem(24);
    }

    @include media-breakpoint-up(lg) {
      font-size: rem(32);
    }

    &.active {
      z-index: 1;
      background-color: $yellow;
      color: $primary;
    }
  }
}

.policyText {
  text-align: left;

  @include media-breakpoint-up($brand-story-policy-breakpoint) {
    text-align: center;
  }
}

.policySubtitle {
  @include underline();
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;

  @include media-breakpoint-up(md) {
    font-size: rem(18);
  }
}

.policyDescription {
  display: block;
  margin-top: rem(16);
  font-size: $font-size-sm;

  @include media-breakpoint-up(md) {
    font-size: $font-size-base;
  }
}

/* ----- Essence ----- */
.essenceHeader {
  margin-bottom: rem(40);
}

.essenceSubtitle {
  @extend .sectionTitle;
  text-transform: uppercase;
}

.essenceContent {
  display: grid;
  grid-template-columns: minmax(0, rem(240));
  justify-content: center;

  @include media-breakpoint-up($brand-story-essence-breakpoint) {
    align-self: start;
    grid-auto-flow: column;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(3, minmax(0, rem(240)));
    column-gap: rem(28);
  }

  @include media-breakpoint-up(lg) {
    column-gap: rem(48);
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: rem(32);
  }
}

.essenceItemTitle {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: rem(16);
  padding-bottom: rem(8);
  border-bottom: rem(8) solid $border-color-base;
  @include font-en(0);
  font-size: rem(24) !important;
  white-space: pre-line;

  @include media-breakpoint-up(md) {
    font-size: rem(32);
    line-height: $line-height-base;
  }
}

.essenceItemContent {
  &:not(:last-child) {
    margin-bottom: rem(40);
  }

  &,
  &:not(:last-child) {
    @include media-breakpoint-up($brand-story-essence-breakpoint) {
      margin-bottom: rem(64);
    }
  }
}

.essenceItemDescription {
  display: block;
  margin-bottom: rem(8);
  font-size: $font-size-sm;
}

.essenceItemKeyword {
  @include underline();
  font-size: rem(18);
  font-weight: $font-weight-bold;
}

.essenceDescription {
  position: relative;
  justify-self: center;
  @include underline();
  font-size: rem(20);
  font-weight: $font-weight-bold;

  @include media-breakpoint-up($brand-story-identity-breakpoint) {
    @include underline(rem(16));
    font-size: rem(36);
  }
}
