@import '../acropassMicroneedle/acropassMicroneedle.module.scss';

.textColored {
  color: $skyblue;
}

.overlay {
  &::before {
    background-color: rgba($black, 0.7);
  }
}

/* ----- Main content ----- */
.productCategory {
  font-family: $font-family-base;
  letter-spacing: 0.166em;
}

/* ----- Animation slides ----- */
.slideWrapper {
  animation-delay: $karada-arrange-slide-initial-animation-delay +
    $karada-arrange-slide-animation-duration *
    (2 / 3 * ($karada-arrange-num-slides - 1) + 1);
}

.slide {
  padding: rem(24) 0;
  animation-duration: $karada-arrange-slide-animation-duration;

  // opacity 0 (fadein start) : opacity 1 : opacity 0 (fadeout & next slide animation start)
  // = duration 1 : 1 : 1
  @for $i from 1 through $karada-arrange-num-slides {
    &:nth-child(#{$i}) {
      animation-delay: $karada-arrange-slide-initial-animation-delay +
        ($karada-arrange-slide-animation-duration * 2 / 3 * ($i - 1));
    }
  }

  @include media-breakpoint-up(md) {
    padding: map-get($section-padding-spacers, md);
  }

  &.slideBg03 {
    background-image: url('../../assets/images/karada-arrange-lactic-acid-bacteria-bg-01.jpg');
    background-size: cover;
    background-position: 8%;

    @include media-breakpoint-up(lg) {
      background-position: 18%;
    }
  }

  &.slideBg04 {
    &::after {
      @include position(absolute, 0, 0);
      z-index: -1;
      width: 100%;
      height: 100%;
      background-image: url('../../assets/images/karada-arrange-brand-story-top-01.jpg');
      background-size: cover;
      background-position: center;
      content: '';
    }

    &.blur {
      &::after {
        filter: blur(4px);
      }
    }
  }
}

:global(.container) {
  &.slideContainerGutterSm {
    padding: 0 rem(15);

    @include media-breakpoint-up(sm) {
      padding: 0 $gutter-width;
    }
  }
}

.slideContentHalf {
  max-width: rem(290);
  margin: 0 auto;

  @include media-breakpoint-up(sm) {
    max-width: rem(424);
  }

  @include media-breakpoint-up(md) {
    width: 57.65%;
    max-width: rem(544);
    margin-right: 0;
  }
}

.slideContent {
  row-gap: rem(16);
}

.slideCaption {
  &::before {
    background-color: $skyblue;
  }
}

.slideSubtitle {
  display: flex;
  align-items: center;
  margin-bottom: rem(8);

  @include media-breakpoint-up(md) {
    margin-bottom: rem(16);
  }

  svg {
    width: auto;
    height: 0.75em;
    margin-right: rem(4);

    @include media-breakpoint-up(md) {
      height: 1em;
    }
  }
}

.slideTitleLg {
  font-size: rem(32);

  @include media-breakpoint-up(md) {
    font-size: rem(48);
  }
}

.circle {
  &.position03 {
    @include position(absolute, 50%, 50%);
    transform: translate(-50%, -50%);
  }
}
