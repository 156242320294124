/**
 * ----------------------------------------
 * Position
 * ----------------------------------------
 */

@mixin position(
  $position,
  $top: null,
  $left: null,
  $right: null,
  $bottom: null
) {
  position: $position;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}

/**
 * ----------------------------------------
 * Aspect ratio
 * ----------------------------------------
 */

@mixin aspect-ratio($width: 16, $height: 9, $target: div) {
  position: relative;

  &::before {
    display: block;
    padding-top: $height / $width * 100%;
    content: '';
  }

  @if $target {
    #{$target} {
      @include position(absolute, 0, 0);
      width: 100%;
      height: 100%;
      @content;
    }
  }
}

@mixin aspect-ratio-img(
  $width: 16,
  $height: 9,
  $object-fit: cover,
  $object-position: null
) {
  @include aspect-ratio($width, $height, img) {
    object-fit: $object-fit;
    object-position: $object-position;
  }
}

/**
 * ----------------------------------------
 * Typography
 * ----------------------------------------
 */

@mixin font-en($letter-spacing: $letter-spacing-en) {
  font-family: $font-family-en;
  font-weight: $font-weight-ex-bold;
  letter-spacing: $letter-spacing;
}

@mixin font-en-condensed($letter-spacing: $letter-spacing-en) {
  font-family: $font-family-en-condensed;
  font-weight: $font-weight-bold;
  letter-spacing: $letter-spacing;
}

/**
 * ----------------------------------------
 * Text decoration
 * ----------------------------------------
 */

@mixin underline($line-width: rem(8), $color: $yellow-translucent) {
  background-image: linear-gradient(
    to top,
    $color $line-width,
    transparent $line-width
  );
}

/**
 * ----------------------------------------
 * Links
 * ----------------------------------------
 */

@mixin links($color: $text-primary, $hover-color: darken($color, 15%)) {
  color: $color;

  &:hover {
    color: $hover-color;
  }
}

/**
 * ----------------------------------------
 * Forms
 * ----------------------------------------
 */

@mixin focus-box-shadow($color) {
  box-shadow: focus-box-shadow($color);
}

/**
 * ----------------------------------------
 * Responsive
 * ----------------------------------------
 */

// $values: map --- key: breakpoint, value: value
@mixin responsive($property, $values) {
  @each $breakpoint, $value in $values {
    @include media-breakpoint-up($breakpoint) {
      #{$property}: $value;
    }
  }
}

/**
 * ----------------------------------------
 * Truncation
 * ----------------------------------------
 */

@mixin truncation($num-lines: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $num-lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/**
 * ----------------------------------------
 * Overlay
 * ----------------------------------------
 */

@mixin overlay($color: $primary, $opacity: 0.7) {
  &::before {
    @include position(absolute, 0, 0, 0, 0);
    background-color: rgba($color, $opacity);
    content: '';
  }
}

/**
 * ----------------------------------------
 * Button
 * ----------------------------------------
 */

// @mixin button-fill($color) {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   color: white;
//   background-color: $color;
//   transition: background-color 0.3s, color 0.3s;
//   box-shadow: 0 3px 7px 1px rgba(0, 0, 0, 0.4);
//   border-radius: rem(3);
//   padding: rem(4) rem(16);
//   &:hover {
//     background: lighten($color, 10%);
//     transition: all 0.3s ease;
//   }
//   &:active {
//     background: lighten($color, 10%);
//   }
// }

// @mixin button-outline($color) {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   border: 1px solid $color;
//   background-color: white;
//   color: $color;
//   border-radius: rem(3);
//   padding: rem(4) rem(16);
//   transition: border-color 0.3s, background-color 0.3s, color 0.3s;
//   &:hover {
//     color: white;
//     background: lighten($color, 10%);
//     transition: all 0.3s ease;
//   }
//   &:active {
//     color: white;
//     background: lighten($color, 10%);
//   }
// }
