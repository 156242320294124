@import '../../../styles/core';

.root {
  display: grid;
  grid-template-rows: auto 1fr auto;
  text-align: center;
  font-family: $font-family-base;
  height: 100%;
  white-space: pre-line;

  .imageContainer {
    @include aspect-ratio-img(164, 144, contain);
    margin-bottom: rem(40);
  }

  .title {
    font-size: rem(14);
    font-weight: bold;
    margin-bottom: rem(8);

    @include media-breakpoint-down(sm) {
      font-size: rem(12);
    }
  }

  .textContainer {
    border-bottom: 1px solid $whitesmoke;
    margin-bottom: rem(4);
  }

  .name {
    font-size: rem(24);
    font-weight: bold;

    @include media-breakpoint-down(sm) {
      font-size: rem(20);
    }
  }

  .reading {
    font-size: rem(10);
    color: $gray;
    margin-bottom: rem(16);
  }
}
