@import '../../styles/core';

.caption {
  display: block;
  margin-bottom: rem(40);
  font-family: $font-family-en-secondary;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  letter-spacing: 0.33em;

  @include media-breakpoint-up(md) {
    margin-bottom: rem(60);
  }
}

/* ----- brand story ----- */
.brandStorySection {
  padding: rem(64) 0;
  text-align: center;

  @include media-breakpoint-up($bihada-factory-mission-breakpoint) {
    padding: rem(80) 0;
  }
}

/* ----- Our mission ----- */
.missionSection {
  padding: rem(64) 0;
  text-align: center;
  background-color: $light-whitesmoke;

  @include media-breakpoint-up($bihada-factory-mission-breakpoint) {
    padding: rem(80) 0;
  }
}

.missionTitle {
  height: auto;
  margin-bottom: rem(24);

  @include media-breakpoint-up(md) {
    width: rem(234);
    margin-bottom: rem(32);
  }
}

/* ----- Profile ----- */
.profileSection {
  padding: rem(160) 0 rem(80);
  position: relative;
  text-align: center;

  @include media-breakpoint-up($bihada-factory-profile-breakpoint) {
    padding: rem(176) 0 rem(120);
  }

  &::before {
    @include position(absolute, (rem(24) * -1), 50%);
    height: rem(144);
    border-left: $border-width-base solid $border-color-base;
    transform: translateX(-50%);
    content: '';
  }
}

.profileTitle {
  @extend .missionTitle;

  @include media-breakpoint-up(md) {
    width: rem(264);
  }
}

.profileContent {
  display: grid;
  row-gap: rem(40);
  @include list-unstyled();

  @include media-breakpoint-up($bihada-factory-profile-breakpoint) {
    row-gap: rem(120);
  }
}

.profileItem {
  display: grid;
  row-gap: rem(16);
  border-radius: 50%;
  position: relative;
  font-size: $font-size-sm;
  text-align: left;

  @include media-breakpoint-up($bihada-factory-profile-breakpoint) {
    align-items: center;
    column-gap: rem(48);
    min-height: rem(320);
    padding: rem(52) rem(80);
    border-radius: 50rem;

    &:nth-child(odd) {
      grid-template-columns: 1fr 1.4fr;
    }

    &:nth-child(even) {
      grid-template-columns: 1.4fr 1fr;
      margin-left: auto;
    }
  }

  @include media-breakpoint-up(lg) {
    width: 91.5%;
    max-width: rem(976);
  }

  &::before {
    z-index: -1;
    width: rem(288);
    height: rem(288);
    margin: 0 auto;
    border-radius: 50rem;
    content: '';

    @include media-breakpoint-up($bihada-factory-profile-breakpoint) {
      position: absolute;
      width: 100%;
      height: 100%;
      padding-top: 0;
    }
  }

  // Color variant
  // Set gradient background based on index (odd/even)
  @each $color, $values in $bihada-factory-profile-colors {
    $i: index($bihada-factory-profile-colors, $color $values);

    &.#{$color} {
      &::before {
        background-image: linear-gradient(
          to bottom,
          map-get($values, background-color),
          $white
        );

        @include media-breakpoint-up($bihada-factory-profile-breakpoint) {
          @if $i % 2 == 0 {
            background-image: linear-gradient(
              to left,
              $white,
              map-get($values, background-color)
            );
          } @else {
            background-image: linear-gradient(
              to right,
              $white,
              map-get($values, background-color)
            );
          }
        }
      }
    }
  }
}

.profileItemText {
  .profileItem:nth-child(odd) & {
    @include media-breakpoint-up($bihada-factory-profile-breakpoint) {
      grid-column-end: -1;
    }
  }
}

.profileItemTitle {
  margin-bottom: rem(16);
  color: $primary;
  font-family: $font-family-secondary;
  font-size: rem(24);
  line-height: 1;
  letter-spacing: 0.125em;
}

.profileItemDescription {
  white-space: pre-line;
}

.profileItemCaption {
  @extend .caption;
  display: flex;
  align-items: center;
  margin-bottom: rem(8);

  // Color variant
  @each $color, $values in $bihada-factory-profile-colors {
    .profileItem.#{$color} & {
      color: map-get($values, color);
    }
  }

  &::before {
    display: inline-block;
    width: rem(12);
    height: rem(12);
    border-radius: 50%;
    margin-right: rem(8);
    background-color: currentColor;
    content: '';
  }
}

.profileItemImg {
  position: absolute;
  grid-row: 1 / span 1;
  grid-column: 1;
  align-self: end;
  justify-self: center;
  width: 100%;
  max-width: rem(272);

  @include media-breakpoint-up($bihada-factory-profile-breakpoint) {
    align-self: auto;
    justify-self: auto;
  }

  .profileItem:nth-child(even) & {
    @include media-breakpoint-up($bihada-factory-profile-breakpoint) {
      grid-column: 2;
    }
  }

  &.hiaru {
    max-width: rem(236);

    @include media-breakpoint-up($bihada-factory-profile-breakpoint) {
      top: rem(72);
      max-width: rem(248);
    }

    @include media-breakpoint-up(lg) {
      max-width: rem(272);
    }
  }

  &.nidoru {
    max-width: rem(264);

    @include media-breakpoint-up($bihada-factory-profile-breakpoint) {
      top: rem(32);
    }
  }

  &.ikumi {
    align-self: center;
    max-width: rem(152);
    margin-top: rem(16);

    @include media-breakpoint-up($bihada-factory-profile-breakpoint) {
      max-width: rem(164);
      margin-top: 0;
    }

    @include media-breakpoint-up(xl) {
      margin-left: rem(24);
    }
  }

  &.mamoru {
    align-self: center;
    max-width: rem(156);

    @include media-breakpoint-up($bihada-factory-profile-breakpoint) {
      justify-self: end;
      max-width: rem(188);
      margin-right: rem(80);
    }

    @include media-breakpoint-up(xl) {
      margin-right: rem(112);
    }
  }
}

/* ----- Page links ----- */
.pageLinks {
  display: grid;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.pageLinksBlock {
  padding: rem(48) rem(40);
  color: $white;

  &.bgGradientPurple {
    background-image: linear-gradient($red-purple, $royal-purple);
  }

  &.bgGradientPacificBlue {
    background-image: linear-gradient($light-pacific-blue, $pacific-blue);
  }
}

.pageLinksBlockInner {
  display: grid;
  row-gap: rem(24);
  max-width: rem(240);
  margin: 0 auto;

  svg {
    justify-self: center;
    margin-bottom: rem(32);
  }
}
