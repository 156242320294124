@import '../../styles/core';

.section {
  @include responsive(padding, $section-padding-lg-spacers);
}

.title {
  @extend %mb-lg;
  @extend %title;
  text-align: center;
}

.categoriesWrap {
  @include position(relative, $left: 50%);
  width: 100vw;
  margin-left: -50vw;
  @extend %mb-xl;
  overflow-x: scroll;
}

.categories {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: rem(8);
  @include list-unstyled(); // Bootstrap mixin
  width: min-content;
  margin: 0 auto;
  padding: 0 $gutter-width;

  // Container max widths
  @each $breakpoint, $max-width in $max-widths {
    @include media-breakpoint-up($breakpoint) {
      max-width: $max-width;
    }
  }

  @include media-breakpoint-up($faq-breakpoint) {
    justify-content: center;
    gap: rem(16);
  }
}

.btnCategory {
  white-space: nowrap;
}

.content {
  @include list-unstyled();
  max-width: $inner-max-width;
  margin: 0 auto;
}

.faqItem {
  border-bottom: $border-width-base solid $border-color-base;
}

.question {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  width: 100%;
  padding: rem(16) rem(8);
  text-align: left;
  cursor: pointer;

  @include media-breakpoint-up($faq-breakpoint) {
    padding-top: rem(32);
    padding-bottom: rem(32);
  }

  &:focus {
    outline: none;
  }

  // Parent:first-child
  .faqItem:first-child & {
    padding-top: 0;
  }

  &::before {
    margin-right: rem(28);
    @include font-en(0);
    font-size: rem(36);
    line-height: $line-height-sm;
    content: 'Q';
  }
}

.questionText {
  margin-right: rem(16);
  margin-bottom: 0;
  font-size: $font-size-sm;
  transition: color $transition-duration;

  @include media-breakpoint-up($faq-breakpoint) {
    margin-right: rem(32);
    font-size: $font-size-base;
  }

  // Parent on hover & with 'active'
  .question:hover &,
  .question.active & {
    color: $secondary;
  }
}

.toggle {
  position: relative;
  width: rem(24);
  height: rem(24);

  &::before {
    @include position(absolute, 50%, 0);
    width: 100%;
    border-bottom: 2px solid $border-color-base;
    transform: translateY(-50%);
    transition: border-color $transition-duration;
    content: '';
  }

  &::after {
    @include position(absolute, 0, 50%);
    height: 100%;
    border-left: 2px solid $border-color-base;
    transform: translateX(-50%);
    transition: border-color $transition-duration, opacity $transition-duration;
    content: '';
  }

  // Parent on hover & with 'active'
  .question:hover &::before,
  .question:hover &::after,
  .question.active &::before,
  .question.active &::after {
    border-color: $secondary;
  }

  .question.active &::after {
    opacity: 0;
  }
}

.answer {
  background-color: $whitesmoke;

  p {
    padding: rem(16); // Setting padding to parent makes animation not smooth
    font-size: $font-size-sm;
    white-space: pre-line;

    @include media-breakpoint-up($faq-breakpoint) {
      padding: rem(32);
      font-size: $font-size-base;
    }
  }
}
